import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getPackagesInbox} from "../../../redux/features/inboxSlice";
import Loading from "../../Loading";
import DashboardHeader from "../DashboardHeader";
import PackagesList from "./PackagesList";
import {ToastContainer} from "react-toastify";
import {SearchBar} from "../../SearchBar";
import {fetchInsurance} from "../../../redux/features/InsuranceSlice";

function InboxComponent() {
    const dispatch = useDispatch();
    const packages = useSelector((state) => state.inbox.packages);
    const loading = useSelector((state) => state.inbox.loading);
    const [search, setSearch] = useState(null);
    const [activeIcon, setActiveIcon] = useState(null);
    const [items, setItems] = useState([]);
    const [promoCode, setPromoCode] = useState()

    useEffect(() => {
        dispatch(
            getPackagesInbox({
                country_code: localStorage.getItem("currentBranchCode"),
                status: null,
            })
        );

        dispatch(fetchInsurance({country_code: localStorage.getItem("currentBranchCode")}))
    }, [dispatch]);


    useEffect(() => {

        setPromoCode(packages?.promocode)

        if (activeIcon === 1) {
            setItems(packages?.packages?.filter(item => item.status.toLowerCase() === 'in review'));
        }
        if (activeIcon === 2) {
            setItems(packages?.packages?.filter(item => item.status.toLowerCase() === 'action needed'));
        }
        if (activeIcon === 0) {
            setItems(packages?.packages?.filter(item => item.status.toLowerCase() === 'ready to send'));
        }
        if (activeIcon === null) {
            setItems(packages?.packages)
        }
        if (search) {
            setItems(items?.filter((packs) =>
                packs.sender.toLowerCase().includes(search.trim().toLowerCase())
            ));
            setItems(items?.filter((packs) =>
                packs.track_number.toLowerCase().includes(search.trim().toLowerCase())
            ));

        }
    }, [search, packages, activeIcon, items]);


    return (
        <div className="home_content">
            <ToastContainer/>

            <DashboardHeader withInsurance={true}/>
            {/* {NOTE Notifications} */}
            <SearchBar
                packsCount={items?.length ?? 0}
                activeIcon={activeIcon}
                setActiveIcon={setActiveIcon}
                searchEmit={setSearch}
                hasPromo={promoCode}
                withPromo={true}
                withCount={true}
                withInsurance={true}
            />

            {loading ? <Loading/> :
                (items?.map((item, i) => <PackagesList item={item} key={i}/>))
            }
        </div>
    );
}

export default InboxComponent;
