import React, {useState} from "react";
import passwordIcon from "../../assets/images/unlock.svg";
import logImg from "../../assets/images/log.svg";
import axios from "axios";
import {Link, NavLink} from "react-router-dom";
import {LOGIN_API} from "../../apis/apis";
import {toast, ToastContainer} from "react-toastify";
import {useTranslation} from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";


function SignInForm() {
    const {t} = useTranslation();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [btnStatus, setBtnStatus] = useState(true);



    const recaptchaHandler = (response) => {
        if(response){
            setBtnStatus(false)
        }else{
            setBtnStatus(true)
        }
    }

    const recaptchaExpiredHandler = () => {
        setBtnStatus(true)
    }


    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const body = {
                email,
                password,
            };
            const {data} = await axios.post(LOGIN_API, body, {
                headers: {
                    apiKey: process.env.REACT_APP_API_KEY,
                },
            });
            window.location.pathname = "/";

            localStorage.setItem("token", data.data.token);

            localStorage.setItem("user", JSON.stringify(data.data.user));
            localStorage.setItem(
                "currentBranchCode",
                JSON.parse(localStorage.getItem("user")).merchant.current_branch_code
            );
        } catch (error) {
            toast.error(error.response.data.message);
        }
    };
    return (
        <section className="log-in">
            <ToastContainer/>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <form className="content-log" onSubmit={handleLogin}>
                            <h4>
                                {t("signIn")} |{" "}
                                <NavLink style={{color: "#8A23FE"}} to="/signup">
                                    {t("signUp")}
                                </NavLink>
                            </h4>
                            <p className="line-log"></p>

                            <div className="input-log">
                                <input
                                    type="text"
                                    placeholder={t("phoneOrEmail")}
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    required
                                />
                            </div>

                            <div className="input-log">
                                <img src={passwordIcon} loading="lazy" alt={t('passwordIcon')}/>
                                <input
                                    type={`${showPassword ? "text" : "password"}`}
                                    placeholder={t("enterPass")}
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    required
                                />
                                {/* <i className="fas fa-eye"></i> */}
                            </div>

                            <div className="flex-log-chek-and-foregt">
                                <div className="log-ckeckbox ms-1">
                                    <input
                                        type="checkbox"
                                        onChange={() => setShowPassword(!showPassword)}
                                    />
                                    <span className={"ms-2"}> {t("showPassword")}</span>
                                </div>

                            <div className="text-right small">
                                <Link style={{color: "#8A23FE", fontSize: "0.7rem"}} to={"/forgot-password"}>{t('forgotPassword')}</Link>
                            </div>
                            </div>


                            <div className="f-flex align-items-center justify-content-center mt-3">
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                                    hl={localStorage.getItem('APP_LANGUAGE') ?? 'en'}
                                    onChange={recaptchaHandler}
                                    onExpired={recaptchaExpiredHandler}
                                />

                            </div>

                            {/* <div className="flex-log-chek-and-foregt">
              <div className="log-ckeckbox">
                <input type="checkbox" />
                <span>remember me </span>
              </div>
              <a href="foreget-password.html" className="foregt-password">
                Forgot Password?
              </a>
            </div> */}


                            <button type="submit" className="but-log-in" disabled={btnStatus}>
                                {t("signIn")}
                            </button>
                        </form>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="image-log">
                            <img className="text-right" src={logImg} alt={t('logo')} loading="lazy"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SignInForm