import {SRC_IMG_API} from "../../apis/apis";
import {Trans, useTranslation} from "react-i18next";
import React, {useState} from "react";
import ResultCard from "./ResultCard";
import isDelivered from "../../assets/svgs/isDelivered.svg"
import packageSvg from "../../assets/svgs/package.svg"
import CalculatorModal from "../CalculatorModal";
import InfoCard from "./InfoCard";

const CalculationResults = ({results, location, unites, setResults}) => {
    const {t} = useTranslation();
    const [isOpened, setIsOpened] = useState(false);

    return (
        <div className={"container"}>
            <div className="row">
                <div className="col-8 mb-0">
                    <div className="card shadow-el rounded-lg px-2 mb-0" style={{zIndex: 1}}>
                        <div className="card-body">
                            <div className="d-flex align-items-center justify-content-start" style={{gap: '1rem'}}>
                                <img width={30} style={{objectFit: 'contain'}} src={`${SRC_IMG_API}/${location.icon}`}
                                     alt={location.name}/>
                                <div className={"d-flex flex-column"} style={{lineHeight: 1}}>
                                    <span className={"text-secondary small"}>{t('From')}</span>
                                    <span className={"font-weight-bold"}>{location.name}</span>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="card shadow-el rounded-lg px-2">
                        <div className="card-body">
                            <div className="d-flex align-items-center justify-content-start" style={{gap: '1rem'}}>
                                <svg className={"mb-1"} width="30" height="30" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.9999 14.1699C9.86988 14.1699 8.12988 12.4399 8.12988 10.2999C8.12988 8.15994 9.86988 6.43994 11.9999 6.43994C14.1299 6.43994 15.8699 8.16994 15.8699 10.3099C15.8699 12.4499 14.1299 14.1699 11.9999 14.1699ZM11.9999 7.93994C10.6999 7.93994 9.62988 8.99994 9.62988 10.3099C9.62988 11.6199 10.6899 12.6799 11.9999 12.6799C13.3099 12.6799 14.3699 11.6199 14.3699 10.3099C14.3699 8.99994 13.2999 7.93994 11.9999 7.93994Z"
                                        fill="#292D32"/>
                                    <path
                                        d="M12.0002 22.76C10.5202 22.76 9.03018 22.2 7.87018 21.09C4.92018 18.25 1.66018 13.72 2.89018 8.33C4.00018 3.44 8.27018 1.25 12.0002 1.25C12.0002 1.25 12.0002 1.25 12.0102 1.25C15.7402 1.25 20.0102 3.44 21.1202 8.34C22.3402 13.73 19.0802 18.25 16.1302 21.09C14.9702 22.2 13.4802 22.76 12.0002 22.76ZM12.0002 2.75C9.09018 2.75 5.35018 4.3 4.36018 8.66C3.28018 13.37 6.24018 17.43 8.92018 20C10.6502 21.67 13.3602 21.67 15.0902 20C17.7602 17.43 20.7202 13.37 19.6602 8.66C18.6602 4.3 14.9102 2.75 12.0002 2.75Z"
                                        fill="#292D32"/>
                                </svg>
                                <div className={"d-flex flex-column"} style={{lineHeight: 1}}>
                                    <span className={"text-secondary small"}>{t('To')}</span>
                                    <span className={"font-weight-bold"}>{results[0].office}</span>
                                </div>

                                {results[0].is_deliver ?
                                    <img onClick={() => setIsOpened(true)} src={isDelivered} alt="isDelivered"
                                         className={"ms-auto"}
                                         style={{cursor: 'pointer'}}/> : null
                                }


                            </div>
                        </div>
                    </div>


                </div>

            </div>
            <div className="row mt-5">

                <div className="col-lg-4 col-sm-12 h-100">
                    <ResultCard results={results[0]} weight={unites.weight}/>
                </div>
                <div className="col-lg-4 col-sm-12 h-100">
                    <ResultCard results={results[1]} weight={unites.weight}/>
                </div>
                <div className="col-lg-4 col-sm-12 h-100">
                    <ResultCard results={results[2]} weight={unites.weight}/>
                </div>
            </div>

            <div className="row mt-5">
                <div className="col-12">
                    <InfoCard title={t('FreeInsurance')}
                              icon={packageSvg}>
                        <p className={"m-0 p-0"}>{t('freeInsuranceMsg')}</p>
                    </InfoCard>
                </div>
                <div className="col-12 mt-4">
                    <InfoCard title={t("TAXFree")}
                              icon={packageSvg}>
                        <p className={"m-0 p-0"}>{t('taxFreeMsg')}</p>
                    </InfoCard>
                </div>
                <div className="col-12 mt-4">

                    <InfoCard title={t("CashOnDelivery")}
                              icon={packageSvg}>
                        <p className={"m-0 p-0"}>{t('cashMsg')}</p>
                    </InfoCard>
                </div>
                {location.code === 'usa' && <>
                    <div className="col-12 mt-4">
                        <InfoCard title={t('Repackaging')}
                                  icon={packageSvg}>
                            <p className={"m-0 p-0"}>{t('repackagingMsg')}</p>
                        </InfoCard>
                    </div>
                    <div className="col-12 mt-4">
                        <InfoCard title={t("IraqiCustomsFee")}
                                  icon={packageSvg}>
                            <Trans i18nKey={"iraqiMsg"} />
                        </InfoCard>
                    </div>
                    <div className="col-12 mt-4">
                        <InfoCard title={t("RestrictedItems")}
                                  icon={packageSvg}>
                            <Trans i18nKey={"restMsg"} />
                        </InfoCard>
                    </div>
                </>
                }
            </div>

            <div className="row mt-5">
                <button onClick={() => setResults(null)} className="span-one p-2 ms-auto mt-4">
                    <svg className={"me-2"} width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.57 5.92993L3.5 11.9999L9.57 18.0699" stroke="#fff" strokeWidth="1.5"
                              strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M20.5 12H3.67004" stroke="#fff" strokeWidth="1.5" strokeMiterlimit="10"
                              strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>

                    {t('calculateAnotherShipment')}</button>
            </div>
            <CalculatorModal isOpened={isOpened} type={'deliver'} setIsOpen={setIsOpened}/>

        </div>
    )
}

export default CalculationResults;