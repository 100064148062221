import React from "react";
import "./selectableShipment.css";
import {useTranslation} from "react-i18next";
import DeliveryRequestStatusEnum from "../DeliveryRequestStatusEnum";
import {Link} from "react-router-dom";

export const DeliveryRequest = ({request, cancelHandler}) => {
    const {t} = useTranslation();


    return (
        <section className="new-content-suite mt-4 shadow-el">
            <div className="content-suite-header-content-flex">
                <article className="part-left-title-content-suite w-100">
                    <div className="d-flex align-item-start justify-content-between w-100">
                        <article className="row w-100">
                            <div className={"col-12 col-lg-2 request-first"}>
                                <p className="font-weight-bold text-dark mb-0">{request.name}</p>
                                <p className="text-dark mb-0">{new Date(request.created_at).toLocaleDateString(undefined, {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })}</p>
                            </div>

                            <div className={"col-12 col-lg-6 align-items-start align-items-md-center d-flex pl-3 pl-md-5 flex-column flex-md-row"} style={{gap: "1rem"}}>
                                <div
                                    className="part1-flex-weight-and-value ">
                                    <div className="part1-bg-icon"
                                         style={{backgroundColor: request.status != 'pending' && '#329a63'}}>
                                        <svg width="25" height="25" viewBox="0 0 20 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12 0.92V9.23C12 10.25 11.17 11.08 10.15 11.08H1C0.45 11.08 0 10.63 0 10.08V3.69C0 1.65 1.65 0 3.69 0H11.07C11.59 0 12 0.41 12 0.92Z"
                                                fill="#fff"/>
                                            <path
                                                d="M19.5 13.5C19.78 13.5 20 13.72 20 14V15C20 16.66 18.66 18 17 18C17 16.35 15.65 15 14 15C12.35 15 11 16.35 11 18H9C9 16.35 7.65 15 6 15C4.35 15 3 16.35 3 18C1.34 18 0 16.66 0 15V13C0 12.45 0.45 12 1 12H10.5C11.88 12 13 10.88 13 9.5V4C13 3.45 13.45 3 14 3H14.84C15.56 3 16.22 3.39 16.58 4.01L17.22 5.13C17.31 5.29 17.19 5.5 17 5.5C15.62 5.5 14.5 6.62 14.5 8V11C14.5 12.38 15.62 13.5 17 13.5H19.5Z"
                                                fill="#fff"/>
                                            <path
                                                d="M6 20C7.10457 20 8 19.1046 8 18C8 16.8954 7.10457 16 6 16C4.89543 16 4 16.8954 4 18C4 19.1046 4.89543 20 6 20Z"
                                                fill="#fff"/>
                                            <path
                                                d="M14 20C15.1046 20 16 19.1046 16 18C16 16.8954 15.1046 16 14 16C12.8954 16 12 16.8954 12 18C12 19.1046 12.8954 20 14 20Z"
                                                fill="#fff"/>
                                            <path
                                                d="M20 10.53V12H17C16.45 12 16 11.55 16 11V8C16 7.45 16.45 7 17 7H18.29L19.74 9.54C19.91 9.84 20 10.18 20 10.53Z"
                                                fill="#fff"/>
                                        </svg>
                                    </div>
                                    <div className="part1-cotnent">
                                        <p>{t("Status")}</p>
                                        <h5>
                                            {t(DeliveryRequestStatusEnum[request.status])}
                                        </h5>
                                    </div>
                                </div>

                                <div className="part1-flex-weight-and-value">
                                    <div className="part1-bg-icon">
                                        <svg width="20" height="20" viewBox="0 0 18 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M11.3498 0H6.64977C5.60977 0 4.75977 0.84 4.75977 1.88V2.82C4.75977 3.86 5.59977 4.7 6.63977 4.7H11.3498C12.3898 4.7 13.2298 3.86 13.2298 2.82V1.88C13.2398 0.84 12.3898 0 11.3498 0Z"
                                                fill="#fff"/>
                                            <path
                                                d="M14.2391 2.81949C14.2391 4.40949 12.9391 5.70949 11.3491 5.70949H6.64906C5.05906 5.70949 3.75906 4.40949 3.75906 2.81949C3.75906 2.25949 3.15906 1.90949 2.65906 2.16949C1.24906 2.91949 0.289062 4.40949 0.289062 6.11949V15.5295C0.289062 17.9895 2.29906 19.9995 4.75906 19.9995H13.2391C15.6991 19.9995 17.7091 17.9895 17.7091 15.5295V6.11949C17.7091 4.40949 16.7491 2.91949 15.3391 2.16949C14.8391 1.90949 14.2391 2.25949 14.2391 2.81949ZM9.37906 14.9495H4.99906C4.58906 14.9495 4.24906 14.6095 4.24906 14.1995C4.24906 13.7895 4.58906 13.4495 4.99906 13.4495H9.37906C9.78906 13.4495 10.1291 13.7895 10.1291 14.1995C10.1291 14.6095 9.78906 14.9495 9.37906 14.9495ZM11.9991 10.9495H4.99906C4.58906 10.9495 4.24906 10.6095 4.24906 10.1995C4.24906 9.78949 4.58906 9.44949 4.99906 9.44949H11.9991C12.4091 9.44949 12.7491 9.78949 12.7491 10.1995C12.7491 10.6095 12.4091 10.9495 11.9991 10.9495Z"
                                                fill="#fff"/>
                                        </svg>

                                    </div>
                                    <div className="part1-cotnent">
                                        <p>{request.total_packs} {t('packages')}</p>
                                        <h5>{request.total_boxes} {t('boxes')}</h5>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="part1-flex-weight-and-value col-12 col-lg-4 align-items-center justify-content-end d-flex">

                                {request.status === 'pending' &&

                                    <button
                                        onClick={() => cancelHandler(request.id)}
                                        className="delivery-requests-btn btn"
                                    >
                                        {t("Cancel")}
                                    </button>}


                                <Link
                                    to={`view/${request.id}`}
                                    className="delivery-requests-btn btn"
                                >
                                    {t("view Details")}
                                </Link>


                            </div>
                        </article>
                    </div>
                </article>
            </div>
        </section>
    )
}