import React from "react";
import {useTranslation} from "react-i18next";

function Address({user, branch}) {
    const {t} = useTranslation();

    return (
        <div className="addres-header-flex">
            <div className="box-image-address">
                <svg width="35" height="50" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M17.6211 7.45C16.5711 2.83 12.5411 0.75 9.00107 0.75C9.00107 0.75 9.00107 0.75 8.99107 0.75C5.46107 0.75 1.42107 2.82 0.371072 7.44C-0.798928 12.6 2.36107 16.97 5.22107 19.72C6.28107 20.74 7.64107 21.25 9.00107 21.25C10.3611 21.25 11.7211 20.74 12.7711 19.72C15.6311 16.97 18.7911 12.61 17.6211 7.45ZM9.00107 12.46C7.26107 12.46 5.85107 11.05 5.85107 9.31C5.85107 7.57 7.26107 6.16 9.00107 6.16C10.7411 6.16 12.1511 7.57 12.1511 9.31C12.1511 11.05 10.7411 12.46 9.00107 12.46Z"
                        fill="#7604FE"/>
                </svg>
            </div>
            <div className="content-address-flex"  style={{lineHeight: "0.5", marginTop:"10px"}}>
                <p className={"font-weight-bold mb-0 pb-0"}>{t("Yourwasil")}</p>
                <h2 className={"font-weight-bolder mt-0 pt-0 mb-0"} dir={'ltr'}>{branch.name} {t('usaAddress')}</h2>
            </div>
        </div>
    );
}

export default Address;
