import React, {useEffect, useState} from "react";
import {CANCEL_DELIVERY_REQUESTS} from "../../apis/apis";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {getDeliveryRequests} from "../../redux/features/DeliveryRequestsSlice";
import Loading from "../../components/Loading";
import {DeliveryRequest} from "../../components/DeliveryReqest";
import {toast, ToastContainer} from "react-toastify";
import {useTranslation} from "react-i18next";

export const MyRequests = () => {
    const [loading, setLoading] = useState(true)
    const requests = useSelector(state => state.deliveryRequests.requests)
    const dispatch = useDispatch();
    const [t] = useTranslation()

    useEffect(() => {
        dispatch(getDeliveryRequests());
        setLoading(false);
        // console.log(requests);
    }, [dispatch]);

    const CancelRequestHandler = async (id) => {
        setLoading(true);
        try {
            await axios.get(
                CANCEL_DELIVERY_REQUESTS + `/${id}`,
                {
                    headers: {
                        apiKey: process.env.REACT_APP_API_KEY,
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );

            toast.success('Request Canceled Successfully');


        } catch (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                window.location.pathname = "/signin";
            } else {
                toast.error('Request Cancellation Failed');
            }

        } finally {
            dispatch(getDeliveryRequests());
            setLoading(false)
        }

    }

    return (
        <div style={{marginTop: "2.2rem"}}>
            <ToastContainer/>


            <h5 style={{fontWeight: 500}} className={"mb-3"}>{t('yourHomeDeliveryRequests')}</h5>
            {loading ? <Loading/> : requests?.map(request => <DeliveryRequest request={request}
                                                                              cancelHandler={CancelRequestHandler}/>)}
        </div>
    )
}