import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {fetchUserWallet} from "../../redux/features/WalletSlice";

export const Wallet = (props) => {
    const [t] = useTranslation()
    const {wallet: userWallet} = useSelector(state => state.wallet)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUserWallet());
    }, [dispatch]);
    return (
        <div style={{marginTop: "2.2rem"}}>
            <h5 style={{fontWeight: 500}} className={"mb-3"}>{t('Wallet')}</h5>

            <div className="content-suite-header-content-flex shadow-el rounded-lg mt-4">
                <article className="part-left-title-content-suite w-100">
                    <div className="d-flex align-item-start justify-content-between w-100">
                        <article className="row w-100 flex-wrap">

                            <div className={"col-12 px-0 wallet-header"}>
                                <p className={"pb-0 mb-0 font-weight-bold"}>{t('CurrentBalance')}</p>
                                <h1 className={"font-weight-bold"} style={{color: "#6612d3"}}>{userWallet?.amount}
                                    <span style={{fontSize: "1.5rem"}} className={"text-muted"}>
                                    IQD
                                    </span>
                                </h1>
                            </div>

                        </article>
                    </div>
                </article>
            </div>

            <div className="content-suite-header-content-flex shadow-el rounded-lg mt-0">
                <article className="part-left-title-content-suite w-100">
                    <div className=" row">
                        <div className="col-12 wallet-header">

                        <p className={"mt-4 pb-0 mx-3 mb-0 font-weight-bold"}>{t('YourWalletTransactions')}</p>
                        </div>
                        <div className="col-12">
                            <article className="table-responsive">
                                <table className="table wallet-table">
                                    <thead>
                                    <tr>
                                        <th scope="col">{t('No.')}</th>
                                        <th scope="col">{t('Amount')}</th>
                                        <th scope="col">{t('Transaction Type')}</th>
                                        <th scope="col">{t('Purpose')}</th>
                                        <th scope="col">{t('By')}</th>
                                        <th scope="col">{t('Voucher Number')}</th>
                                        <th scope="col">{t('Other Details')}</th>
                                        <th scope="col">{t('Date')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {userWallet?.history?.map((item, index) =>
                                        <tr>
                                            <td>{index+1}</td>
                                            <td>{item.amount}</td>
                                            <td>{item.transaction_type}</td>
                                            <td>{item.purpose}</td>
                                            <td>{item.by}</td>
                                            <td>{item.payment_voucher_number}</td>
                                            <td>{item.other_details}</td>
                                            <td>{item.created_at && new Date(item.created_at).toLocaleDateString(undefined, {
                                                month: "long",
                                                day: "2-digit",
                                                year: 'numeric'
                                            })}</td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>


                            </article>

                        </div>
                    </div>
                </article>
            </div>
        </div>
    )
}