import React, {useEffect, useState} from "react";
import Sidebar from "../../components/dashboard/Sidebar";
import boxImg from "../../assets/images/img-box1.svg";
import testProfileImg from "../../assets/images/tst-image1.jpg";
import {Trans, useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getMyMemberships} from "../../redux/features/settingsSlice";
import {getMyBranch} from "../../redux/features/myBranchSlice";


function Shipments() {
  const {t} = useTranslation();

  const [showTable, setShowTable] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const dispatch = useDispatch();

  const user = useSelector((state) => state.myBranch.user);

  useEffect(() => {
    dispatch(getMyBranch());
  }, [dispatch]);
  return (
    <div id="admin-page">
      <div className="holder-dashboard">
        <Sidebar />
        <div className="home_content">
   
          <div className="New-header-tops-flex">
            <div className="part-one-header-flex">
              <button className="but-puls" type="button">
                <i className="bx bx-plus"></i>
                <span>{t('pulsMembership')}</span>
              </button>
              <button className="but-Number">
                <span>{user.suite}</span>
              </button>
              <button className="but-none-bg">
                <span>{t('4Days12HoursUntilNextShipmentFromUsa')}</span>
              </button>
            </div>

            <div id="NewNotofication" className="New-Notofication">
              <i
                className="bx bx-bell"
                onClick={() => setShowNotifications(!showNotifications)}
              ></i>
            </div>
          </div>

          <div
            id="viewNotoficationToggle"
            className="view-toggle-notofication"
            style={{ display: `${showNotifications ? "block" : "none"}` }}
          >
            <div className="card-item-ntofiation">
              <article className="art-flex-info-notofication">
                <img src={testProfileImg} alt={t('profileImage')} loading="lazy"/>
                <div className="name-and-time-noto">
                  <h5>UserName</h5>
                  <span>{t('timeAndData')}</span>
                </div>
              </article>
              <p>
                {t('loremIpsumDolorSitAmetConsecteturAdipisicingElitSi')}
              </p>
            </div>
            <hr />
            <div className="card-item-ntofiation">
              <article className="art-flex-info-notofication">
                <img src={testProfileImg} alt={t('profileImage')} loading="lazy"/>
                <div className="name-and-time-noto">
                  <h5>UserName</h5>
                  <span>{t('timeAndData')}</span>
                </div>
              </article>
              <p>
                {t('loremIpsumDolorSitAmetConsecteturAdipisicingElitSi')}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="new-box-searhc1">
                <span className="bx bx-search"></span>
                <input type="search" placeholder={t('searchHere2')} />
              </div>
            </div>
          </div>
          <section className="new-content-suite">
            <div className="content-suite-header-content-flex">
              <article className="part-left-title-content-suite">
                <img src={boxImg} alt={t('box')} loading="lazy"/>
                <article>
                  <h5>
                      <Trans
                          i18nKey="shipmentIdWsc6567543"
                          components={{
                              s: <span/>
                          }}
                      />
                  </h5>

                  <p>{t('january252021')}</p>
                </article>
              </article>

              <article className="part-right-title-content-suite">
                <div className="">
                  <p>{t('packageStatus')}</p>
                  <h5>{t('onTheWay')}</h5>
                </div>
                <div className="prat-tight-iocn">
                  <div className="box-part-right-icon ic-right-bg-three">
                    <span className="bx bxs-truck"></span>
                  </div>
                </div>
              </article>
            </div>

            <div
              id="newTable"
              className="newTable"
              style={{ display: `${showTable ? "block" : "none"}` }}
            >
              <div className="content-suite-header-content-flex">
                <article className="part-left-title-content-suite">
                  <img src={boxImg} alt={t('box')} loading="lazy"/>
                  <article>
                    <h5>
                        <Trans
                            i18nKey="amazon"
                        /> <span>1Z7587568758758787575</span>
                    </h5>
                    <p>{t('january252021')}</p>
                  </article>
                </article>

                <article className="part-right-title-content-suite">
                  <div className="">
                    <p>{t('packageStatus')}</p>
                    <h5>{t('readyToSend')}</h5>
                  </div>
                  <div className="prat-tight-iocn">
                    <div className="box-part-right-icon ic-right-bg-four">
                      <span className="bx bx-check"></span>
                    </div>
                  </div>
                </article>
              </div>
              <table border="1">
                <thead>
                  <tr>
                    <th>
                      <span>{t('briogeohair')}</span> | 420342499374804877788659
                    </th>
                    <th>{t('quantity')}</th>
                    <th>{t('valuePerUnit')}</th>
                    <th>{t('totalValue')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="active-tr">
                    <td data-label="">
                      {t('smartWatchesWristBandBlackColor')}
                    </td>
                    <td data-label="Quantity">1</td>
                    <td data-label="Value per unit">$100</td>
                    <td data-label=" Total value">$100</td>
                  </tr>
                  <tr className="">
                    <td data-label="">
                      {t('smartWatchesWristBandBlackColor')}
                    </td>
                    <td data-label="Quantity">1</td>
                    <td data-label="Value per unit">$100</td>
                    <td data-label=" Total value">$100</td>
                  </tr>
                </tbody>
              </table>
              <div className="button-table">
                <button className="viewPicture">{t('viewPicture')}</button>
              </div>
              <table border="1">
                <thead>
                  <tr>
                    <th>
                      <span>{t('briogeohair')}</span> | 420342499374804877788659
                    </th>
                    <th>{t('quantity')}</th>
                    <th>{t('valuePerUnit')}</th>
                    <th>{t('totalValue')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="active-tr">
                    <td data-label="">
                      {t('smartWatchesWristBandBlackColor')}
                    </td>
                    <td data-label="Quantity">1</td>
                    <td data-label="Value per unit">$100</td>
                    <td data-label="Total value">$100</td>
                  </tr>
                  <tr className="">
                    <td data-label="Briogeohair  |  420342499374804877788659">
                      {t('smartWatchesWristBandBlackColor')}
                    </td>
                    <td data-label="Quantity">1</td>
                    <td data-label="Value per unit">$100</td>
                    <td data-label=" Total value">$100</td>
                  </tr>
                </tbody>
              </table>
              <div className="button-table">
                <button className="viewPicture">{t('viewPicture')}</button>
              </div>
            </div>

            <div className="card-edting-button-page towFlex-shipment">
              <div className="card-edting-part1">
                <div className="part1-flex-weight-and-value">
                  <div className="part1-bg-icon">
                    <span className="bx bx-line-chart"></span>
                  </div>
                  <div className="part1-cotnent">
                    <p>{t('weight')}</p>
                    <h5>{t('12Pounds')}</h5>
                  </div>
                </div>
                <div className="part1-flex-weight-and-value">
                  <div className="part1-bg-icon">
                    <span className="bx bx-dollar"></span>
                  </div>
                  <div className="part1-cotnent">
                    <p>{t('vulue')}</p>
                    <h5>{t('40000Iqd')}</h5>
                  </div>
                </div>
                <div className="part1-flex-weight-and-value">
                  <div className="part1-bg-icon">
                    <span className="bx bx-file"></span>
                  </div>
                  <div className="part1-cotnent">
                    <p>{t('4Package')}</p>
                    <h5>{t('2Boxes')}</h5>
                  </div>
                </div>
              </div>
              <div className="card-edting-part2">
                <button className="viewPicture">{t('invoice')}</button>
                <button className="EditPrice">{t('track')}</button>

                <button
                  type="button"
                  id="TogglesIcon"
                  className={`bx ${
                    showTable ? "bx-chevron-up" : "bx-chevron-down"
                  } button-toggle-arrows`}
                  onClick={() => setShowTable(!showTable)}
                ></button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Shipments;
