import {Link, useNavigate, useParams} from "react-router-dom";
import {t} from "i18next";
import React, {useEffect, useState} from "react";
import DashboardLayout from "../../../layouts/DashboardLayout";
import {CITIES_API, UPDATE_USER_ADDRESS} from "../../../apis/apis";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {getAddresses} from "../../../redux/features/deliveryAddressSlice";

export const Edit = () => {
    const [cities, setCities] = useState([]);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const navigate = useNavigate();
    const {id} = useParams();
    const dispatch = useDispatch();
    const addresses = useSelector(state => state.deliveryAddresses.addresses);
    const fetchCities = async () => {
        try {
            const {data} = await axios.get(CITIES_API, {
                headers: {
                    apiKey: process.env.REACT_APP_API_KEY,
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "multipart/form-data",
                },
            });

            setCities(data.data.cities);
        } catch (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                window.location.pathname = "/signin";
            } else {
                console.log(error);
            }
        }
    }


    const handleSave = async () => {
        if (name === '' || phone === '' || address === '' || city === '') {
            return toast.error('All Fields Are Required');
        }

        try {
            await axios.post(UPDATE_USER_ADDRESS, {
                name,
                phone,
                address,
                city_id: city,
                address_id: id
            }, {
                headers: {
                    apiKey: process.env.REACT_APP_API_KEY,
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "multipart/form-data",
                },
            });

            toast.success("Address Updated Successfully");
            setTimeout(() => {

                backHandler()


            }, 3000)
        } catch (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                window.location.pathname = "/signin";
            } else {
                console.log(error);
            }
        }

    }


    useEffect(() => {
        dispatch(getAddresses());
    }, [dispatch]);

    useEffect(() => {
        fetchCities();

        let filteredAddress = addresses?.filter(item => item.id == id);
        if (filteredAddress?.length > 0) {
            setAddress(filteredAddress[0].address)
            setName(filteredAddress[0].name)
            setPhone(filteredAddress[0].phone)
            setCity(filteredAddress[0].city?.id)

        } else {
            console.log(addresses)
            if (addresses.length > 0 && filteredAddress.length == 0) {
                navigate(-1)
            }
        }

    }, [id, addresses]);



    const backHandler = () => {
        navigate(-1, {state: {
                previousLink: 'address'
            }});
    }



    return (
        <DashboardLayout>
            <ToastContainer/>
            <div className="home_content">
                <div style={{marginTop: "2.2rem"}}>
                    <h5 style={{fontWeight: 500}} className={"mb-3"}>{t('editAddress')}</h5>

                    <div
                        className="content-suite-header-content-flex shadow-el rounded-lg mt-5 col-12 col-md-6 mx-auto">
                        <article className="part-left-title-content-suite w-100">
                            <div className="d-flex align-item-start justify-content-between w-100">
                                <article className="row w-100 flex-wrap">
                                    <div className={"col-12 my-3"}>
                                        <label className="font-weight-bold" style={{color: "#6612d3"}} htmlFor="name">
                                            {t("Receiver name")}:
                                        </label>
                                        <input type="text" id="name" className="form-control" value={name}
                                               onChange={e => setName(e.target.value)}
                                               placeholder={t('Enter Receiver Name')}/>
                                    </div>
                                    <div className={"col-12 my-3"}>
                                        <label className="font-weight-bold" style={{color: "#6612d3"}} htmlFor="phone">
                                            {t("Receiver Phone")}:
                                        </label>
                                        <input type="tel" id="phone" className="form-control" value={phone}
                                               onChange={e => setPhone(e.target.value)}
                                               placeholder={t('Enter Phone number')}/>
                                    </div>

                                    <div className={"col-12 my-3"}>
                                        <label className="font-weight-bold" style={{color: "#6612d3"}} htmlFor="address">
                                            {t("Address")}:
                                        </label>
                                        <textarea id="address" className="form-control" value={address}
                                                  onChange={e => setAddress(e.target.value)}
                                                  placeholder={t('Enter Address')}></textarea>
                                    </div>
                                    <div className={"col-12 my-3"}>
                                        <label className="font-weight-bold" style={{color: "#6612d3"}} htmlFor="city">
                                            {t("City")}:
                                        </label>
                                        <select value={city} className="custom-select"
                                                onChange={e => setCity(e.target.value)}>
                                            <option value="">{t('Select City')}</option>
                                            {
                                                cities?.map(city => <option value={city.id}
                                                                            key={city.id}>{city.name}</option>)
                                            }
                                        </select>
                                    </div>
                                    <div className={"w-100 text-right mt-5 mb-3"}>
                                        <button
                                            onClick={backHandler}
                                            className="delivery-requests-btn btn" style={{color: "#DC3545"}}>{t('cancel')}
                                        </button>
                                        <button
                                            onClick={handleSave}
                                            className="delivery-requests-btn btn" style={{color: "#6612d3"}}>{t('update')}
                                        </button>

                                    </div>
                                </article>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}