import React from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import MemberShipCard from "../../components/dashboard/MemberShipCard";


export const MemberShips = () => {
    const {memberships} = useSelector((state) => state.settings);
    const [t] = useTranslation()
    return (
        <div style={{marginTop: "2.2rem"}}>
            <h5 style={{fontWeight: 500}} className={"mb-5"}>{t('memberships')}</h5>

            {memberships.map((membership) => (
                <MemberShipCard
                    key={membership.hub_id}
                    membership={membership}
                    s={t('expireDate')}
                    s1={t('membership3')}
                    s2={t('membership2')}
                    s3={t('changeMembership')}
                    alt={t('icon')}
                />

            ))}


        </div>
    )
}