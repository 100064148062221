import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {GET_USER_WALLET} from "../../apis/apis";


const initialState = {
    wallet: null
}


export const fetchUserWallet = createAsyncThunk('wallet/fetchUserWallet', async () =>{
    try {
        const { data } = await axios.get(GET_USER_WALLET, {
            headers: {
                apiKey: process.env.REACT_APP_API_KEY,
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        return data.data;
    } catch (error) {
        if (error.response.status === 401 ) {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            window.location.pathname = "/signin";

        } else {
            console.log(error);
        }
    }
})

const WalletSlice = createSlice({
    name: 'wallet',
    initialState,
    extraReducers: {
        [fetchUserWallet.pending]: (state, action) => {
            state.loading = true;
        },
        [fetchUserWallet.fulfilled]: (state, action) => {
            state.loading = false;
            state.wallet = action.payload;
        },
        [fetchUserWallet.rejected]: (state, action) => {
            state.loading = false;
        },
    }
})


export default WalletSlice.reducer;