import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {GET_USER_DELIVERY_REQUESTS} from "../../apis/apis";

const initialState = {
    requests: [],
    NewRequestStep: 1
};

export const getDeliveryRequests = createAsyncThunk(
    "deliveryRequests",
    async () => {
        try {
            const {data} = await axios.get(
                GET_USER_DELIVERY_REQUESTS,
                {
                    headers: {
                        apiKey: process.env.REACT_APP_API_KEY,
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );

            return data.data;
        } catch (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                window.location.pathname = "/signin";
            }

        }
    }
);

const deliveryRequestsSlice = createSlice({
    name: "deliveryRequests",
    initialState,
    reducers: {
        goNextStep: (state) => {
            state.NewRequestStep = state.NewRequestStep + 1;
        },
        goBackStep: (state) => {
            state.NewRequestStep = state.NewRequestStep - 1;
        },
        resetSteps: (state) => {
            state.NewRequestStep = 1;
        }
    },
    extraReducers: {
        [getDeliveryRequests.fulfilled]: (state, action) => {
            state.requests = action.payload
        },
    },
});

export const {goNextStep, resetSteps, goBackStep} = deliveryRequestsSlice.actions
export default deliveryRequestsSlice.reducer;
