import {Link} from "react-router-dom";
import * as PropTypes from "prop-types";
import React from "react";
import axios from "axios";
import {DELETE_USER_ADDRESS} from "../../apis/apis";
import {getAddresses} from "../../redux/features/deliveryAddressSlice";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

function SingleAddressCard(props) {
    const dispatch = useDispatch();
    const {t} = useTranslation()


    const deleteAddress = async () => {
        try {
            await axios.get(DELETE_USER_ADDRESS + props.id, {
                headers: {
                    apiKey: process.env.REACT_APP_API_KEY,
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "multipart/form-data",
                },
            });
            dispatch(getAddresses());
        } catch (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                window.location.pathname = "/signin";
            } else {
                console.log(error);
            }
        }
    }


    return (
        <div className="content-suite-header-content-flex shadow-el rounded-lg">
        <article className="part-left-title-content-suite w-100">
            <div className="d-flex align-item-start justify-content-between w-100">
                <article className="row w-100 flex-wrap">
                    <div className={"col-12 col-md-2 request-first"}>
                        <p className="font-weight-bolder text-dark mb-0">{props.name}</p>
                        <p className="font-weight-bold text-dark mb-0">{props.city.name ?? props.city}</p>
                    </div>

                    <div className={"col-12 col-md-5 pl-5"}>
                        <p className="text-dark mb-0">{props.address}</p>
                        <p className="text-dark mb-0">{props.phone}</p>
                    </div>

                    {
                        props.showActions &&

                        <div
                            className="part1-flex-weight-and-value col-12 col-md-5 align-items-center justify-content-end d-flex">


                            <button
                                onClick={deleteAddress}
                                className="delivery-requests-btn btn"
                            >
                                {t('delete')}
                            </button>


                            <Link
                                to={`/dashboard/delivery-address/edit/${props.id}`}
                                className="delivery-requests-btn btn"
                            >
                                {t('edit')}
                            </Link>


                        </div>
                    }


                </article>
            </div>
        </article>
    </div>
    );
}

SingleAddressCard.propTypes = {
    name: PropTypes.any,
    city: PropTypes.any,
    address: PropTypes.any,
    phone: PropTypes.any,
    showActions: PropTypes.bool,
    id: PropTypes.any
};

export default SingleAddressCard;