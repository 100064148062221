import React from 'react'
import {useTranslation} from 'react-i18next';
import {Link, useNavigate, useParams} from "react-router-dom";
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import axios from "axios";
import {DELETE_USER_ADDRESS} from "../../../apis/apis";
import {useDispatch} from "react-redux";
import {getAddresses} from "../../../redux/features/deliveryAddressSlice"; // Import css


function SingleAddress({address}) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const options = {
        title: 'Want to delete this address?',
        message: 'This action is permanent cannot be recovered',
        buttons: [
            {
                label: 'Yes',
                onClick: () => deleteAddress()
            },
            {
                label: 'Cancel',
                onClick: () => {
                }
            }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
    }


    const deleteAddress = async () => {
        try {
            await axios.get(DELETE_USER_ADDRESS + address.id, {
                headers: {
                    apiKey: process.env.REACT_APP_API_KEY,
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "multipart/form-data",
                },
            });
            dispatch(getAddresses());
        } catch (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                window.location.pathname = "/signin";
            } else {
                console.log(error);
            }
        }
    }

    return (
        <div className="cards-bg-wites">
            <div className="bule-cards p-2" style={{height: 'auto'}}>
                <Link to={`/dashboard/delivery-address/edit/${address.id}`}><i
                    className="fa fa-edit text-white"/></Link>
                <button onClick={() => confirmAlert(options)} className="btn btn-link"><i className="fa fa-trash ml-2" style={{color: '#b72121'}}/></button>
            </div>
            <div className="card-tables-flex-new">
                <div className="row mt-5">
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <div className="tables-content">
                            <p>
                                {t("Receiver name")}
                                <br/>
                                <strong>{address.name}</strong>
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <div className="tables-content">
                            <p>
                                {t("address")}
                                <br/>
                                <strong>{address.address}</strong>
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <div className="tables-content">
                            <p>
                                {t("city")}
                                <br/>
                                <strong>{address.city.name}</strong>
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <div className="tables-content">
                            <p>
                                {t("City Delivery Fees")}
                                <br/>
                                <strong>{address.city.delivery_fees}</strong>
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <div className="tables-content">
                            <p>
                                {t("phoneNum")}
                                <br/>
                                <strong>{address.phone}</strong>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SingleAddress