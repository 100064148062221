import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {toast, ToastContainer} from "react-toastify";
import Select from "react-select";
import axios from "axios";
import cbmImg from "../../assets/svgs/white_cbm.svg"
import {CALCULATOR_API, CALCULATOR_POST_API, CITIES_API, SRC_IMG_API} from "../../apis/apis";


function FormCalculate({setLoading, setResult, setLocations, unites, setUnites}) {
    const {t} = useTranslation();
    const [countries, setCountries] = useState()
    const [cities, setCities] = useState()
    const [selectedFrom, setSelectedFrom] = useState(null);
    const [selectedTo, setSelectedTo] = useState(null);
    const [dimensions, setDimensions] = useState({});


    useEffect(() => {
        if (selectedFrom?.code == 'usa') {
            setUnites({
                weight: t('lb'),
                height: t('inch')
            })

            document.querySelectorAll('.dim-class').forEach(div => {
                div.style.display = 'block';
            });
        }
        else if(selectedFrom?.code == 'uss'){
            setUnites({
                weight: t('CBM'),
                height: t('inch')
            })
            document.querySelectorAll('.dim-class').forEach(div => {
                div.style.display = 'none';
            });
        }
        else {
            setUnites({
                weight: t('kg'),
                height: t('cm')
            })
            document.querySelectorAll('.dim-class').forEach(div => {
                div.style.display = 'block';
            });
        }

        setLocations(selectedFrom)
    }, [selectedFrom])

    const fetchCountries = async () => {
        try {
            const {data} = await axios.get(CALCULATOR_API, {
                headers: {
                    apiKey: process.env.REACT_APP_API_KEY, Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            setCountries(data.data?.hubs)
        } catch (err) {
            toast.error(err.message)
        }
    }

    const fetchCities = async () => {
        try {
            const {data} = await axios.get(CITIES_API, {
                headers: {
                    apiKey: process.env.REACT_APP_API_KEY, Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            setCities(data.data?.cities)
        } catch (err) {
            toast.error(err.message)
        }
    }

    useEffect(() => {
        fetchCountries()
        fetchCities()
    }, []);


    const filterOptions = (option, search) => {
        if (option.data.name?.toLowerCase().includes(search?.toLowerCase())) {
            return true;
        } else {
            return false;
        }
    }


    const customStyles = {
        control: (provided, state) => ({
            ...provided, borderColor: state.isFocused ? 'rgba(129, 21, 255, 1)' : '#CED4DA', '&:hover': {
                borderColor: 'rgba(129, 21, 255, 1)',
            }
        }), option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'rgba(129, 21, 255, 0.2)' : state.isSelected ? 'rgba(129, 21, 255, 1)' : 'transparent',
            '&:active': {
                backgroundColor: state.isFocused ? 'rgba(129, 21, 255, 1)' : 'transparent', color: 'white'
            }
        }),
    }


    const changeDimensionsHandler = (dimension, value) => {
        setDimensions(old => ({...old, [dimension]: value}))
    }
    const calculateHandler = async () => {
        if (!selectedFrom) {
            return toast(t('pleaseEnterFromCountry'), {type: 'error'})
        }


        if (!selectedTo) {
            return toast(t('pleaseEnterToCity'), {type: 'error'})
        }
        if (!dimensions.weight || dimensions.weight === '') {
            return toast(t('pleaseEnterPackageWeight'), {type: 'error'})
        }
        if (!dimensions.length || dimensions.length === '') {
            // return toast(t('pleaseEnterPackageLength'), {type: 'error'})
            dimensions.length = 1;
        }
        if (!dimensions.width || dimensions.width === '') {
            // return toast(t('pleaseEnterPackageWidth'), {type: 'error'})
            dimensions.width = 1;
        }
        if (!dimensions.height || dimensions.height === '') {
            // return toast(t('pleaseEnterPackageHeight'), {type: 'error'})
            dimensions.height = 1;
        }

        // console.log(dimensions);
        
        try {
            setLoading(true)

            const {data} = await axios.post(CALCULATOR_POST_API, {
                hub_id: selectedFrom.id,
                city_id: selectedTo.id,
                weight: dimensions.weight,
                length: dimensions.length,
                width: dimensions.width,
                height: dimensions.height
            }, {
                headers: {
                    apiKey: process.env.REACT_APP_API_KEY, Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });

            if(data.success){
                setResult(data.results)
            }
        } catch (ex) {
            toast.error(ex.message);
        }
        setLoading(false)
    }

    return (
        <>
            <ToastContainer/>
            <div className="container">
                <h5 className="margintops">{t("descFormCalc")}</h5>
                <div className="row mt-5">
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className={"font-weight-bold mb-2"}>
                            <svg className={"me-2 mb-1"} width="20" height="20" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
                                    fill="#292D32"/>
                                <path
                                    d="M9.00023 21.75H8.00023C7.59023 21.75 7.25023 21.41 7.25023 21C7.25023 20.59 7.57023 20.26 7.98023 20.25C6.41023 14.89 6.41023 9.11 7.98023 3.75C7.57023 3.74 7.25023 3.41 7.25023 3C7.25023 2.59 7.59023 2.25 8.00023 2.25H9.00023C9.24023 2.25 9.47023 2.37 9.61023 2.56C9.75023 2.76 9.79023 3.01 9.71023 3.24C7.83023 8.89 7.83023 15.11 9.71023 20.77C9.79023 21 9.75023 21.25 9.61023 21.45C9.47023 21.63 9.24023 21.75 9.00023 21.75Z"
                                    fill="#292D32"/>
                                <path
                                    d="M14.9999 21.7502C14.9199 21.7502 14.8399 21.7402 14.7599 21.7102C14.3699 21.5802 14.1499 21.1502 14.2899 20.7602C16.1699 15.1102 16.1699 8.89018 14.2899 3.23018C14.1599 2.84018 14.3699 2.41018 14.7599 2.28018C15.1599 2.15018 15.5799 2.36018 15.7099 2.75018C17.6999 8.71018 17.6999 15.2702 15.7099 21.2202C15.6099 21.5502 15.3099 21.7502 14.9999 21.7502Z"
                                    fill="#292D32"/>
                                <path
                                    d="M12 17.1998C9.21 17.1998 6.43 16.8098 3.75 16.0198C3.74 16.4198 3.41 16.7498 3 16.7498C2.59 16.7498 2.25 16.4098 2.25 15.9998V14.9998C2.25 14.7598 2.37 14.5298 2.56 14.3898C2.76 14.2498 3.01 14.2098 3.24 14.2898C8.89 16.1698 15.12 16.1698 20.77 14.2898C21 14.2098 21.25 14.2498 21.45 14.3898C21.65 14.5298 21.76 14.7598 21.76 14.9998V15.9998C21.76 16.4098 21.42 16.7498 21.01 16.7498C20.6 16.7498 20.27 16.4298 20.26 16.0198C17.57 16.8098 14.79 17.1998 12 17.1998Z"
                                    fill="#292D32"/>
                                <path
                                    d="M20.9998 9.74986C20.9198 9.74986 20.8398 9.73986 20.7598 9.70986C15.1098 7.82986 8.87978 7.82986 3.22978 9.70986C2.82978 9.83986 2.40978 9.62986 2.27978 9.23986C2.15978 8.83986 2.36978 8.41986 2.75978 8.28986C8.71978 6.29986 15.2798 6.29986 21.2298 8.28986C21.6198 8.41986 21.8398 8.84986 21.6998 9.23986C21.6098 9.54986 21.3098 9.74986 20.9998 9.74986Z"
                                    fill="#292D32"/>
                            </svg>

                            {t('FromCountry')}</label>

                        <Select
                            styles={customStyles}
                            filterOption={filterOptions}
                            placeholder={t('selectFromCountry')}
                            defaultValue={selectedFrom}
                            onChange={setSelectedFrom}
                            options={countries}
                            getOptionValue={item => item.id}
                            getOptionLabel={item => {
                                return (<div className={"d-flex gap-4"}>
                                    <img width={25} style={{objectFit: 'contain'}}
                                         src={`${SRC_IMG_API}/${item.icon}`} alt={item.name}/>
                                    <span className={"ms-2"}>{item.name}</span>
                                </div>)
                            }}
                        />
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4">

                        <label className={"font-weight-bold mb-2"}>
                            <svg className={"me-2 mb-1"} width="20" height="20" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11.9999 14.1699C9.86988 14.1699 8.12988 12.4399 8.12988 10.2999C8.12988 8.15994 9.86988 6.43994 11.9999 6.43994C14.1299 6.43994 15.8699 8.16994 15.8699 10.3099C15.8699 12.4499 14.1299 14.1699 11.9999 14.1699ZM11.9999 7.93994C10.6999 7.93994 9.62988 8.99994 9.62988 10.3099C9.62988 11.6199 10.6899 12.6799 11.9999 12.6799C13.3099 12.6799 14.3699 11.6199 14.3699 10.3099C14.3699 8.99994 13.2999 7.93994 11.9999 7.93994Z"
                                    fill="#292D32"/>
                                <path
                                    d="M12.0002 22.76C10.5202 22.76 9.03018 22.2 7.87018 21.09C4.92018 18.25 1.66018 13.72 2.89018 8.33C4.00018 3.44 8.27018 1.25 12.0002 1.25C12.0002 1.25 12.0002 1.25 12.0102 1.25C15.7402 1.25 20.0102 3.44 21.1202 8.34C22.3402 13.73 19.0802 18.25 16.1302 21.09C14.9702 22.2 13.4802 22.76 12.0002 22.76ZM12.0002 2.75C9.09018 2.75 5.35018 4.3 4.36018 8.66C3.28018 13.37 6.24018 17.43 8.92018 20C10.6502 21.67 13.3602 21.67 15.0902 20C17.7602 17.43 20.7202 13.37 19.6602 8.66C18.6602 4.3 14.9102 2.75 12.0002 2.75Z"
                                    fill="#292D32"/>
                            </svg>
                            {t('toCity')}
                        </label>
                        
                        <Select
                            styles={customStyles}
                            filterOption={filterOptions}
                            placeholder={t('chooseToCity')}
                            defaultValue={selectedTo}
                            onChange={setSelectedTo}
                            options={cities}
                            getOptionValue={item => item.id}    
                            getOptionLabel={item => {
                                let cityName = item.name;
                                let hint = '';
                                
                                // Check if the city is one of the office cities
                                if (['Baghdad', 'Erbil', 'Basra'].includes(cityName)) {
                                    hint = ' (Office)';
                                }

                                
                                return (<div className={"d-flex gap-4"}>
                                        <span className={"ms-2"}>
                                            {item.name} 
                                        </span>
                                        <small className="text-danger fw-bold mx-1">{hint}</small>

                                </div>)
                            }}
                        />
                    </div>
                </div>

                <div className="row mt-5">

                    <div className="col-sm-12 col-md-4 col-lg-4 position-relative input-group">
                        <label htmlFor="weight" className={"font-weight-bold mb-2"}>
                            
                        {selectedFrom?.code == 'uss' ? 
                        <svg id="Layer_1" className={"me-2"} width="18" height="20" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.65 10.75">
                            <path class="cls-1" d="M1.49,9.09c-.05,0-.11-.02-.16-.04l-1.11-.56c-.12-.06-.2-.19-.2-.34v-1.39c0-.2.17-.38.38-.38s.38.17.38.38v1.15l.9.45c.18.09.26.31.16.5-.07.13-.2.21-.34.21Z"/>
                            <path class="cls-1" d="M4.82,10.75s-.06,0-.09-.01c-.03,0-.06-.02-.09-.03l-1.1-.55c-.18-.1-.26-.32-.17-.51.1-.19.32-.26.51-.17l.57.28v-.78c0-.2.17-.38.38-.38s.38.17.38.38v.78l.57-.28c.18-.09.41-.02.51.17.09.18.02.41-.17.51l-1.11.55s-.06.02-.09.03c-.03,0-.06.01-.09.01Z"/>
                            <path class="cls-1" d="M8.16,9.09c-.13,0-.27-.07-.34-.2-.09-.19-.02-.41.17-.51l.91-.45v-1.15c0-.2.17-.38.38-.38s.38.17.38.38v1.39c0,.14-.08.27-.2.34l-1.12.55c-.05.02-.11.04-.16.04Z"/>
                            <path class="cls-1" d="M4.82,6.59c-.21,0-.38-.17-.38-.38v-1.15l-.9-.45c-.18-.1-.26-.32-.17-.5.1-.18.32-.26.51-.17l.94.47.94-.47c.18-.09.41-.02.51.17.09.19.02.41-.17.5l-.9.45v1.15c0,.2-.17.38-.38.38Z"/>
                            <path class="cls-1" d="M.38,4.36c-.2,0-.38-.17-.38-.38v-1.38h0s0-.07.01-.09c0-.05.03-.08.05-.12.01-.03.03-.05.05-.07s.05-.04.07-.05c0,0,.02-.01.03-.02l1.11-.56c.18-.09.41-.01.5.17.09.18.02.41-.16.5l-.44.22.44.22c.18.09.26.32.16.5-.09.19-.32.26-.5.17l-.57-.28v.78c0,.21-.16.38-.38.38Z"/>
                            <path class="cls-1" d="M5.93,1.31c-.06,0-.11-.01-.16-.04l-.95-.47-.95.47c-.19.1-.41.02-.5-.16-.1-.18-.02-.41.16-.51L4.65.04c.11-.05.23-.05.34,0l1.11.55c.18.1.26.32.17.51-.07.13-.2.2-.34.2Z"/>
                            <path class="cls-1" d="M9.26,4.36c-.2,0-.38-.17-.38-.38v-.78l-.57.28c-.19.09-.41.02-.5-.17-.1-.18-.02-.41.17-.5l.44-.22-.44-.22c-.19-.09-.26-.32-.16-.5.09-.18.31-.26.5-.17l1.1.55s.02.01.03.02c.03.01.06.04.08.07.02.02.03.04.05.06.02.04.03.07.05.11,0,.03.01.06.01.09h0v1.39c0,.21-.17.38-.38.38Z"/>
                          </svg>     
                        : 
                            <svg className={"me-2"} width="18" height="20" viewBox="0 0 20 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11.75 21.7505H7.75C2.32 21.7505 0 19.4305 0 14.0005V8.00049C0 2.57049 2.32 0.250488 7.75 0.250488H11.75C17.18 0.250488 19.5 2.57049 19.5 8.00049V14.0005C19.5 19.4305 17.18 21.7505 11.75 21.7505ZM7.75 1.75049C3.14 1.75049 1.5 3.39049 1.5 8.00049V14.0005C1.5 18.6105 3.14 20.2505 7.75 20.2505H11.75C16.36 20.2505 18 18.6105 18 14.0005V8.00049C18 3.39049 16.36 1.75049 11.75 1.75049H7.75Z"
                                    fill="#292D32"/>
                                <path
                                    d="M12.8201 11.5411C12.6401 11.5411 12.4601 11.4711 12.3201 11.3511C10.8601 10.0511 8.64011 10.0511 7.18011 11.3511C7.02011 11.5011 6.80011 11.5611 6.57011 11.5311C6.35011 11.5011 6.16011 11.3711 6.04011 11.1811L3.86011 7.68107C3.67011 7.37107 3.73011 6.97107 4.00011 6.72107C7.28011 3.81107 12.2201 3.81107 15.5001 6.72107C15.7701 6.96107 15.8301 7.37107 15.6401 7.68107L13.4601 11.1811C13.3401 11.3711 13.1501 11.4911 12.9301 11.5311C12.8901 11.5311 12.8501 11.5411 12.8201 11.5411ZM9.75011 8.87107C10.7501 8.87107 11.7401 9.15107 12.6101 9.70107L14.0101 7.45107C11.4801 5.57107 8.01011 5.57107 5.48011 7.45107L6.88011 9.70107C7.76011 9.14107 8.75011 8.87107 9.75011 8.87107Z"
                                    fill="#292D32"/>
                            </svg>
                        }
                        
                            {selectedFrom?.code == 'uss' ? t('yourPackageCBM') : t('yourPackageWeight')}
                        </label>
                        <div className="input-group">

                            <input onChange={(e) => changeDimensionsHandler('weight', e.target.value)}
                                   disabled={!selectedFrom || !selectedTo} type="number" id="weight"
                                   className={"form-control inputNoShadow"}
                                   placeholder={selectedFrom?.code == 'uss' ? t('enterCBM') : t('enterWeight')}/>
                            <div className="input-group-append">
                                <span class="input-group-text" id="basic-addon2">{unites.weight}</span>
                            </div>
                        </div>
                    </div>


                    <div className="dim-class col-sm-12 col-md-4 col-lg-4 position-relative input-group">
                        <label htmlFor="weight" className={"font-weight-bold mb-2"}>
                            <svg className={"me-2 mb-1"} width="22" height="22" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11.9998 13.3003C11.8698 13.3003 11.7398 13.2704 11.6198 13.2004L2.78985 8.09039C2.42985 7.88039 2.30983 7.42036 2.51983 7.06036C2.72983 6.70036 3.17983 6.58034 3.54983 6.79034L11.9998 11.6804L20.3998 6.82037C20.7598 6.61037 21.2198 6.74039 21.4298 7.09039C21.6398 7.45039 21.5098 7.91036 21.1598 8.12036L12.3899 13.2004C12.2599 13.2604 12.1298 13.3003 11.9998 13.3003Z"
                                    fill="#292D32"/>
                                <path
                                    d="M12 13.3004C11.87 13.3004 11.74 13.2704 11.62 13.2004L11.5 12.5005C11.14 12.2905 11.7901 13.3605 12.0001 13.0005C12.2101 12.6405 11.6302 12.2905 12.0002 12.5005L12 11.6804L20.4 6.82037C20.76 6.61037 21.22 6.74039 21.43 7.09039C21.64 7.45039 21.51 7.91036 21.16 8.12036L12.39 13.2004C12.26 13.2604 12.13 13.3004 12 13.3004Z"
                                    fill="white"/>
                                <path
                                    d="M12 22.3605C11.59 22.3605 11.25 22.0205 11.25 21.6105V12.4302C11.25 12.0202 11.59 11.6802 12 11.6802C12.41 11.6802 12.75 12.0202 12.75 12.4302V21.6105C12.75 22.0205 12.41 22.3605 12 22.3605Z"
                                    fill="#292D32"/>
                                <path
                                    d="M12.0001 22.7505C11.1201 22.7505 10.2501 22.5605 9.56012 22.1805L4.22012 19.2105C2.77012 18.4105 1.64014 16.4805 1.64014 14.8205V9.17046C1.64014 7.51046 2.77012 5.59051 4.22012 4.78051L9.56012 1.82049C10.9301 1.06049 13.0701 1.06049 14.4401 1.82049L19.7801 4.79046C21.2301 5.59046 22.3601 7.52047 22.3601 9.18047V14.8305C22.3601 16.4905 21.2301 18.4105 19.7801 19.2205L14.4401 22.1805C13.7501 22.5605 12.8801 22.7505 12.0001 22.7505ZM12.0001 2.75048C11.3701 2.75048 10.7501 2.88049 10.2901 3.13049L4.95013 6.10046C3.99013 6.64046 3.14014 8.07047 3.14014 9.18047V14.8305C3.14014 15.9305 3.99013 17.3705 4.95013 17.9105L10.2901 20.8805C11.2001 21.3905 12.8001 21.3905 13.7101 20.8805L19.0501 17.9105C20.0101 17.3705 20.8601 15.9405 20.8601 14.8305V9.18047C20.8601 8.08047 20.0101 6.64046 19.0501 6.10046L13.7101 3.13049C13.2501 2.88049 12.6301 2.75048 12.0001 2.75048Z"
                                    fill="#292D32"/>
                            </svg>


                            {t('yourPackageLength')}
                        </label>
                        <div className="input-group">

                            <input onChange={(e) => changeDimensionsHandler('length', e.target.value)}
                                   disabled={!selectedFrom || !selectedTo} type="number" id="weight"
                                   className={"form-control inputNoShadow"}
                                   placeholder={t('enterLength')}/>
                            <div className="input-group-append">
                                <span class="input-group-text" id="basic-addon2">{unites.height}</span>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="row mt-5">

                    <div className="dim-class col-sm-12 col-md-4 col-lg-4 position-relative input-group">
                        <label htmlFor="weight" className={"font-weight-bold mb-2"}>
                            <svg className={"me-2 mb-1"} width="22" height="22" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11.9998 13.3003C11.8698 13.3003 11.7398 13.2704 11.6198 13.2004L2.78985 8.09039C2.42985 7.88039 2.30983 7.42036 2.51983 7.06036C2.72983 6.70036 3.17983 6.58034 3.54983 6.79034L11.9998 11.6804L20.3998 6.82037C20.7598 6.61037 21.2198 6.74039 21.4298 7.09039C21.6398 7.45039 21.5098 7.91036 21.1598 8.12036L12.3899 13.2004C12.2599 13.2604 12.1298 13.3003 11.9998 13.3003Z"
                                    fill="white"/>
                                <path
                                    d="M12 13.3004C11.87 13.3004 11.74 13.2704 11.62 13.2004L11.5 12.5005C11.14 12.2905 11.7901 13.3605 12.0001 13.0005C12.2101 12.6405 11.6302 12.2905 12.0002 12.5005L12 11.6804L20.4 6.82037C20.76 6.61037 21.22 6.74039 21.43 7.09039C21.64 7.45039 21.51 7.91036 21.16 8.12036L12.39 13.2004C12.26 13.2604 12.13 13.3004 12 13.3004Z"
                                    fill="#292D32"/>
                                <path
                                    d="M12 22.3605C11.59 22.3605 11.25 22.0205 11.25 21.6105V12.4302C11.25 12.0202 11.59 11.6802 12 11.6802C12.41 11.6802 12.75 12.0202 12.75 12.4302V21.6105C12.75 22.0205 12.41 22.3605 12 22.3605Z"
                                    fill="#292D32"/>
                                <path
                                    d="M12.0001 22.7505C11.1201 22.7505 10.2501 22.5605 9.56012 22.1805L4.22012 19.2105C2.77012 18.4105 1.64014 16.4805 1.64014 14.8205V9.17046C1.64014 7.51046 2.77012 5.59051 4.22012 4.78051L9.56012 1.82049C10.9301 1.06049 13.0701 1.06049 14.4401 1.82049L19.7801 4.79046C21.2301 5.59046 22.3601 7.52047 22.3601 9.18047V14.8305C22.3601 16.4905 21.2301 18.4105 19.7801 19.2205L14.4401 22.1805C13.7501 22.5605 12.8801 22.7505 12.0001 22.7505ZM12.0001 2.75048C11.3701 2.75048 10.7501 2.88049 10.2901 3.13049L4.95013 6.10046C3.99013 6.64046 3.14014 8.07047 3.14014 9.18047V14.8305C3.14014 15.9305 3.99013 17.3705 4.95013 17.9105L10.2901 20.8805C11.2001 21.3905 12.8001 21.3905 13.7101 20.8805L19.0501 17.9105C20.0101 17.3705 20.8601 15.9405 20.8601 14.8305V9.18047C20.8601 8.08047 20.0101 6.64046 19.0501 6.10046L13.7101 3.13049C13.2501 2.88049 12.6301 2.75048 12.0001 2.75048Z"
                                    fill="#292D32"/>
                            </svg>


                            {t('yourPackageWidth')}
                        </label>
                        <div className="input-group">

                            <input onChange={(e) => changeDimensionsHandler('width', e.target.value)}
                                   disabled={!selectedFrom || !selectedTo} type="number" id="weight"
                                   className={"form-control inputNoShadow"}
                                   placeholder={t('enterWidth')}/>
                            <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon2">{unites.height}</span>
                            </div>
                        </div>
                    </div>


                    <div className="dim-class col-sm-12 col-md-4 col-lg-4 position-relative input-group">
                        <label htmlFor="weight" className={"font-weight-bold mb-2"}>
                            <svg className={"me-2 mb-1"} width="22" height="22" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11.9998 13.3003C11.8698 13.3003 11.7398 13.2704 11.6198 13.2004L2.78985 8.09039C2.42985 7.88039 2.30983 7.42036 2.51983 7.06036C2.72983 6.70036 3.17983 6.58034 3.54983 6.79034L11.9998 11.6804L20.3998 6.82037C20.7598 6.61037 21.2198 6.74039 21.4298 7.09039C21.6398 7.45039 21.5098 7.91036 21.1598 8.12036L12.3899 13.2004C12.2599 13.2604 12.1298 13.3003 11.9998 13.3003Z"
                                    fill="#292D32"/>
                                <path
                                    d="M12 13.3004C11.87 13.3004 11.74 13.2704 11.62 13.2004L11.5 12.5005C11.14 12.2905 11.7901 13.3605 12.0001 13.0005C12.2101 12.6405 11.6302 12.2905 12.0002 12.5005L12 11.6804L20.4 6.82037C20.76 6.61037 21.22 6.74039 21.43 7.09039C21.64 7.45039 21.51 7.91036 21.16 8.12036L12.39 13.2004C12.26 13.2604 12.13 13.3004 12 13.3004Z"
                                    fill="white"/>
                                <path
                                    d="M12 22.3605C11.59 22.3605 11.25 22.0205 11.25 21.6105V12.4302C11.25 12.0202 11.59 11.6802 12 11.6802C12.41 11.6802 12.75 12.0202 12.75 12.4302V21.6105C12.75 22.0205 12.41 22.3605 12 22.3605Z"
                                    fill="#292D32"/>
                                <path
                                    d="M12.0001 22.7505C11.1201 22.7505 10.2501 22.5605 9.56012 22.1805L4.22012 19.2105C2.77012 18.4105 1.64014 16.4805 1.64014 14.8205V9.17046C1.64014 7.51046 2.77012 5.59051 4.22012 4.78051L9.56012 1.82049C10.9301 1.06049 13.0701 1.06049 14.4401 1.82049L19.7801 4.79046C21.2301 5.59046 22.3601 7.52047 22.3601 9.18047V14.8305C22.3601 16.4905 21.2301 18.4105 19.7801 19.2205L14.4401 22.1805C13.7501 22.5605 12.8801 22.7505 12.0001 22.7505ZM12.0001 2.75048C11.3701 2.75048 10.7501 2.88049 10.2901 3.13049L4.95013 6.10046C3.99013 6.64046 3.14014 8.07047 3.14014 9.18047V14.8305C3.14014 15.9305 3.99013 17.3705 4.95013 17.9105L10.2901 20.8805C11.2001 21.3905 12.8001 21.3905 13.7101 20.8805L19.0501 17.9105C20.0101 17.3705 20.8601 15.9405 20.8601 14.8305V9.18047C20.8601 8.08047 20.0101 6.64046 19.0501 6.10046L13.7101 3.13049C13.2501 2.88049 12.6301 2.75048 12.0001 2.75048Z"
                                    fill="#292D32"/>
                            </svg>


                            {t('yourPackageHeight')}
                        </label>
                        <div className="input-group">

                            <input onChange={(e) => changeDimensionsHandler('height', e.target.value)}
                                   disabled={!selectedFrom || !selectedTo} type="number" id="weight"
                                   className={"form-control inputNoShadow"}
                                   placeholder={t('enterHeight')}/>
                            <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon2">{unites.height}</span>
                            </div>
                        </div>
                    </div>

                </div>

                    <div className="row mt-5 justify-content-end">
                        <div className="col pt-5">
                            <button className="span-one p-2" onClick={calculateHandler}>{t('calculate')}</button>
                        </div>
                    </div>
            </div>

        </>);
}

export default FormCalculate;
