import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {
    CHANGE_UER_ADDRESS,
    CHANGE_UER_PASSWORD,
    GET_PLANS_API,
    GET_USER_INFO,
    MY_MEMBERSHIPS_API,
    SUBSCRIBE_API
} from "../../apis/apis";

const initialState = {
    memberships: [],
    plans: [],
    subscribeMessage: {},
    loading: false,
    error: null,
    userInfo: {}
};

export const getMyMemberships = createAsyncThunk("settings/memberships", async () => {
    try {
        const {data} = await axios.get(MY_MEMBERSHIPS_API, {
            headers: {
                apiKey: process.env.REACT_APP_API_KEY,
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });

        return data.data;
    } catch (error) {
        if (error.response.status === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            window.location.pathname = "/signin";
        } else {
            console.log(error);
        }
    }
});
export const getPlans = createAsyncThunk("settings/memberships/plans", async (hub_id) => {
    try {
        const {data} = await axios.post(
            GET_PLANS_API,
            {hub_id},
            {
                headers: {
                    apiKey: process.env.REACT_APP_API_KEY,
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
            );
        return data.data;
    } catch (error) {
        if (error.response.status === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            window.location.pathname = "/signin";
        } else {
            console.log(error);
        }
    }
});


export const getUserInfo = createAsyncThunk("settings/memberships/userInfo", async () => {
    try {
        const {data} = await axios.get(
            GET_USER_INFO,
            {
                headers: {
                    apiKey: process.env.REACT_APP_API_KEY,
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );

        return data.data;
    } catch (error) {
        if (error.response.status === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            window.location.pathname = "/signin";
        } else {
            console.log(error);
        }
    }
});


export const subscribe = createAsyncThunk("settings/memberships/subscribe", async (subscribeData) => {

    try {
        const {data} = await axios.post(
            SUBSCRIBE_API,
            subscribeData,
            {
                headers: {
                    apiKey: process.env.REACT_APP_API_KEY,
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        // console.log(data);

        return data;
    } catch (error) {
        if (error.response.status === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            window.location.pathname = "/signin";
        } else {
            console.log(error);
        }
    }
});

export const changeAddress = createAsyncThunk("settings/user/address", async (address) => {
  try {
    const {data} = await axios.post(
        CHANGE_UER_ADDRESS,
        {
            address: address
        },
        {
          headers: {
            apiKey: process.env.REACT_APP_API_KEY,
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
    );
    // console.log(data);

    return data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      window.location.pathname = "/signin";
    } else {
      console.log(error);
    }
  }
});


const settingsSlice = createSlice({
    name: "settings",
    initialState,
    extraReducers: {
        [getUserInfo.pending]: (state, action) => {
            state.loading = true;
        },
        [getUserInfo.fulfilled]: (state, action) => {
            state.userInfo = action.payload;
            state.loading = false;
        },
        [getUserInfo.rejected]: (state, action) => {
            state.loading = false;
        },

        [getMyMemberships.pending]: (state, action) => {
            state.loading = true;
        },
        [getMyMemberships.fulfilled]: (state, action) => {
            state.memberships = action.payload;
            state.loading = false;
        },
        [getMyMemberships.rejected]: (state, action) => {
            state.loading = false;
        },

        [subscribe.pending]: (state, action) => {
            state.loading = true;
        },
        [subscribe.fulfilled]: (state, action) => {
            state.subscribeMessage = action.payload;
            state.loading = false;
            // console.log(action.payload);
        },
        [subscribe.rejected]: (state, action) => {
            state.loading = false;
        },


        [changeAddress.pending]: (state, action) => {
            state.loading = true;
        },
        [changeAddress.fulfilled]: (state, action) => {
            state.userInfo = action.payload;
            state.loading = false;
            // console.log(action.payload);
        },
        [changeAddress.rejected]: (state, action) => {
            state.loading = false;
        },
        [getPlans.pending]: (state, action) => {
            state.loading = true;
        },
        [getPlans.fulfilled]: (state, action) => {
            state.plans = action.payload;
            state.loading = false;
        },
        [getPlans.rejected]: (state, action) => {
            state.loading = false;
        },

    },
});

export default settingsSlice.reducer;
