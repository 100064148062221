import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {GET_ARCHIVE_PACKS, OUTBOX_API} from "../../apis/apis";

const initialState = {
    deliveredShipments: [],
    returnedPacks: [],
    loading: false,
    error: null,
};

export const getPackagesArchive = createAsyncThunk(
    "archive",
    async ({country_code}, {rejectWithValue}) => {
        try {
            const {data} = await axios.post(
                GET_ARCHIVE_PACKS,
                {
                    country_code,
                },
                {
                    headers: {
                        apiKey: process.env.REACT_APP_API_KEY,
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );

            return data.data;
        } catch (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                window.location.pathname = "/signin";
            }

            return rejectWithValue(error.response.data.message);
        }
    }
);


const archiveSlice = createSlice({
    name: "archive",
    initialState,
    extraReducers: {
        [getPackagesArchive.pending]: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        [getPackagesArchive.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = null;
            state.deliveredShipments = action.payload.delivered_shipments;
            state.returnedPacks = action.payload.returned_packs;
            // console.log(action.payload);
        },
        [getPackagesArchive.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export default archiveSlice.reducer;
