import React, {useEffect, useState} from "react";
import Sidebar from "../../components/dashboard/Sidebar";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getPackageInbox} from "../../redux/features/getPackageSlice";
import Loading from "../../components/Loading";
import {SRC_IMG_API} from "../../apis/apis";
import {useTranslation} from "react-i18next";
import picsSvg from "../../assets/svgs/pics.svg";
import backArrowSvg from "../../assets/svgs/backarrow.svg";
import "./pictures.css"


function Pictures() {
    const {t} = useTranslation();
    const packageDetails = useSelector((state) => state.package.package);
    const {loading} = useSelector((state) => state.package);
    const [isFullScreen, setIsFullScreen] = useState(null);
    const {id} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(getPackageInbox(id));
    }, [id]);

    const toggleFullScreen = (id) => {
        setIsFullScreen(old => old === id? null : id);
    };

    const handleBack = () => {
        navigate(-1);
    }


    if (loading) {
        return <Loading/>;
    }
    return (
        <div id="admin-page">
            <div className="holder-dashboard">
                <Sidebar/>

                <div className="home_content" style={{minHeight: "100vh"}}>
                    <div className="home-delivery-flex mb-1">


                        <div className="addres-header-flex align-items-center">
                            <div className="box-image-address">
                                <img src={picsSvg} alt={t('pics')} width={50}/>
                            </div>
                            <div className="content-address-flex"  style={{lineHeight: "0.5", marginTop:"10px"}}    >
                                <p className={"font-weight-bold mb-0 pb-0"}>{t("Your Package")}</p>
                                <h2 className={"font-weight-bolder mt-0 pt-0 mb-0"}>{t("Pictures").toUpperCase()}</h2>
                            </div>
                        </div>

                        <button onClick={handleBack} className={"btn btn-link text-black-50 back-arrow mr-4"} style={{fontSize: "2rem"}}>
                            <img src={backArrowSvg} alt="" width={"40"}/>
                        </button>
                    </div>

                    <div className="content-return mt-5 mb-1">
                        <h6>
                            {packageDetails?.sender} <span className={"mx-2"}>|</span> <span>{packageDetails?.track_number}</span>
                        </h6>
                    </div>
                    <div className="row px-0">
                        {packageDetails?.images?.map((image, i) => (
                            <div className="col-sm-12 col-md-6 col-lg-3" key={i}>
                                <div className="viewPicthers-box px-0">
                                    <img
                                        src={`${SRC_IMG_API}/${image}`}
                                        className={`w-100 ${isFullScreen === image ? 'fullScreen' : ''}`}
                                        style={{width: '350px', height:"350px", objectFit:"cover", border: "1px solid #f5f6f9", borderRadius: "10px"}}
                                        alt={t('packageImage')}
                                        loading="lazy"
                                        width={"350px"}
                                        height={"350px"}
                                        onClick={() => toggleFullScreen(image)}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Pictures;
