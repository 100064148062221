import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const AccountInformationSettings = () => {
    const {userInfo} = useSelector((state) => state.settings);
    const [t] = useTranslation()

    return (
        <div style={{marginTop: "2.2rem"}}>
            <h5 style={{fontWeight: 500}} className={"mb-3"}>{t('accountInformation')}</h5>

            <div className="content-suite-header-content-flex shadow-el rounded-lg mt-5">
                <article className="part-left-title-content-suite w-100">
                    <div className="d-flex align-item-start justify-content-between w-100">
                        <article className="row w-100 flex-wrap align-items-center">
                            <div className={"col-12 col-md-3 request-first"}>
                                <h5 style={{color: "#6612d3"}}>{userInfo?.name}</h5>
                                <p className={"pb-0 mb-0 font-weight-bold"}> {userInfo?.phone}</p>
                            </div>

                            <div className={"col-12 col-md-9 pl-5"}>
                                <p className="text-dark mb-0" style={{whiteSpace: 'pre-line'}}>{userInfo?.address}</p>
                                <p className="text-dark mb-0 pb-0 font-weight-bold">{userInfo?.city}</p>
                            </div>

                        </article>
                    </div>
                </article>

                <Link
                    to={"change"}
                    className="delivery-requests-btn btn"
                >{t('changeAddress')}</Link>
            </div>

        </div>
    )
}