import * as PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";

function SingleAddressCard(props) {

    const {t} = useTranslation()


    const formatPrice = (price, currencySymbol) => {
        const formattedPrice = parseFloat(price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        return  formattedPrice +' ' + currencySymbol;
    }


    return (
        <div className="content-suite-header-content-flex shadow-el rounded-lg">
            <article className="part-left-title-content-suite w-100">
                <div className="d-flex align-item-start justify-content-between w-100">
                    <article className="row w-100 flex-wrap">
                        <div className={"col-12 col-md-3 request-first"}>
                            <h3 className="font-weight-bolder text-dark mb-0">{formatPrice(props.fees, props.currency)}</h3>
                        </div>

                        <div className={"col-12 col-md-8 pl-5"}>
                            <p className="text-dark mb-0">{t('thisDeliveryFeeIsEstimatedExtraChargesMayApplyInCa')}</p>
                        </div>

                    </article>
                </div>
            </article>
        </div>
    );
}

SingleAddressCard.propTypes = {
    fees: PropTypes.string,
    currency: PropTypes.string,
};

export default SingleAddressCard;