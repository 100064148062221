import React, {useEffect, useState} from "react";
import Sidebar from "../../components/dashboard/Sidebar";
import {toast, ToastContainer} from "react-toastify";
import {getPackageInbox} from "../../redux/features/getPackageSlice";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {returnPackage} from "../../redux/features/returnPackageSlice";
import axios from "axios";
import {GET_RETURN_PRICE_API} from "../../apis/apis";
import returnSvg from "../../assets/svgs/return.svg";
import {Trans, useTranslation} from "react-i18next";
import backArrowSvg from "../../assets/svgs/backarrow.svg";
import uploadSvg from '../../assets/svgs/upload.svg';
import {FileUploader} from "react-drag-drop-files";


function ReturnPackage() {

    const [price, setPrice] = useState(null);
    const [invoice, setInvoice] = useState(null);

    const [notes, setNotes] = useState(null);
    const packageDetails = useSelector((state) => state.package.package);
    const error = useSelector((state) => state.returnPackage.error);
    const message = useSelector((state) => state.returnPackage.message);
    const {t} = useTranslation();
    const navigate = useNavigate();

    const {id} = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPackageInbox(id));
        const getPrice = async () => {
            const {data} = await axios.get(GET_RETURN_PRICE_API, {
                headers: {
                    apiKey: process.env.REACT_APP_API_KEY,
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
            });

            setPrice(data.return_price);
        };
        getPrice();
    }, []);
    useEffect(() => {
        if (error !== null) {
            toast.error(error);
        }
    }, [error]);
    useEffect(() => {
        if (message !== null) {
            toast.success(message);
            setTimeout(function () {
                window.location.pathname = "dashboard/inbox"
            }, 3000);

        }
    }, [message]);

    const sendInvoice = async (e) => {
        e.preventDefault();
        // Handle Error Invoice if it was branch code equal usa
        if (packageDetails?.branch?.code === "usa" && invoice === null) {
            return toast.error("Invoice is required");
        }
        if (packageDetails?.branch?.code === "usa") {
            const reader = new FileReader();
            reader.onloadend = () => {
                const body = {
                    package_id: +id,
                    label: reader.result.toString(),
                    extention: invoice?.name.split(".").pop(),
                    notes,
                };
                dispatch(returnPackage(body));

            };
            reader.readAsDataURL(invoice);
        }

        if (packageDetails?.branch?.code !== "usa" && invoice !== null) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const body = {
                    package_id: +id,
                    label: reader.result.toString(),
                    extention: invoice?.name.split(".").pop(),
                    notes,
                };
                dispatch(returnPackage(body));
            };
            reader.readAsDataURL(invoice);
        }

        if (packageDetails?.branch?.code !== "usa" && invoice === null) {
            const body = {
                package_id: +id,
                label: null,
                extention: null,
                notes,
            };
            dispatch(returnPackage(body));
        }
    };

    const handleBack = () => {
        navigate(-1);
    }


    return (
        <div id="admin-page">
            <ToastContainer/>
            <div class="holder-dashboard">
                <Sidebar/>
                <form class="home_content" onSubmit={sendInvoice}>
                    <div class="home-delivery-flex">

                        <div className="addres-header-flex align-items-center">
                            <div className="box-image-address">
                                <img src={returnSvg} alt={t('pics')} width={50}/>
                            </div>
                            <div className="content-address-flex"  style={{lineHeight: "0.5", marginTop:"10px"}}>
                                <p className={"font-weight-bold mb-0 pb-0"}>{t("Edit Your Items")}</p>
                                <h2 className={"font-weight-bolder mt-0 pt-0 mb-0"}>{t("VALUE").toUpperCase()}</h2>
                            </div>
                        </div>


                        <button onClick={handleBack} className={"btn btn-link text-black-50 back-arrow mr-3"}
                                style={{fontSize: "2rem"}}>
                            <img src={backArrowSvg} alt="" width={"40"}/>
                        </button>
                    </div>
                    <div className="content-return mt-5">
                        <h6>
                            {packageDetails?.sender} <span className={"mx-2"}>|</span>
                            <span>{packageDetails?.track_number}</span>
                        </h6>
                        <p className="prag-return mt-5 font-weight-bold">
                            <Trans
                                i18nKey="youCanReturnYourPurchasedItemToTheMentionedBelowFe"
                                components={{
                                    s: <span
                                        style={{color: "#9658fe "}}/>
                                }}
                            />
                        </p>
                        <p className="prag2-retrun mb-1">{t('yourPackageReturnFreeIs')}</p>
                        <div class="card-return-page">
                            <h1 className="mx-2" style={{color: "#850EFE"}}><span
                                style={{fontSize: "2rem"}}>$</span><span style={{fontSize: "4rem"}}>{price}</span></h1>
                            <article>
                                <p className={"font-weight-normal mb-0 pb-0"}
                                   style={{fontSize: "1.3rem"}}>{t("This Package")}</p>
                                <h4 className={"font-weight-bolder mt-0 pt-0 mb-0"}
                                    style={{fontSize: "1.3rem"}}>{t("Return Fee")}</h4>
                            </article>
                        </div>
                    </div>
                    <div className="">
                        <FileUploader name="file" classes={"wrapper-upload mb-3"} handleChange={(file) => setInvoice(file)}>
                            <div className="content">
                                <div className="icon">
                                    <img src={uploadSvg} alt={t('upload')} style={{width: "100px"}}/>
                                </div>
                                <div className="text">{!invoice? 'Drag and drop your files here' : invoice?.name}</div>
                            </div>
                            <div id="cancel-btn">
                                <i className="fas fa-times"></i>
                            </div>
                            <div className="file-name">img1.jfif</div>
                        </FileUploader>
                        {/*<div className="image"></div>*/}

                    </div>
                    <textarea
                        cols="30"
                        rows="5"
                        className="form-control w-100"
                        onChange={(e) => setNotes(e.target.value)}
                        value={notes}
                        required={
                            packageDetails?.branch?.code !== "usa" ? "true" : undefined
                        }
                        placeholder={t('typingNotes')}
                    />

                    <div className="flex-button-uploadImage">
                        <article>
                            <div className="ps-sm-3 text-center text-sm-start mx-3">
                                <input
                                    type="file"
                                    id="uploadImgProfile"
                                    className="d-none"
                                    onChange={(e) => setInvoice(e.target.files[0])}
                                    name=""
                                />

                                <button type="button" id="custom-btn">
                                    <label className={"mb-0"} htmlFor="uploadImgProfile" role="button">
                                        {t('selectFiles')}
                                    </label>
                                </button>
                            </div>

                            {/* <input id="default" type="file" hidden="" /> */}
                        </article>
                        <article className="subMit mr-3">
                            <button type="submit">{t('submit')}</button>
                        </article>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ReturnPackage;
