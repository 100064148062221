import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {Provider} from "react-redux";
import store from "./redux/store";
import "react-toastify/dist/ReactToastify.css";
import {HelmetProvider} from "react-helmet-async";
import "./i18n";
import {BrowserRouter} from "react-router-dom";
import CrispChat from './components/CrispChat';

// const userEmail = '';
// const userNickname = 'Test User';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    
    <React.StrictMode>
        <Provider store={store}>
            <HelmetProvider>
                <BrowserRouter>
                    <App/>
                    <CrispChat />
                    {/* <CrispChat userEmail={userEmail} userNickname={userNickname} /> */}

                </BrowserRouter>
            </HelmetProvider>
        </Provider>
    </React.StrictMode>
);
