import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {getDeliveryRequests} from "../../redux/features/DeliveryRequestsSlice";
import Loading from "../../components/Loading";
import DashboardLayout from "../../layouts/DashboardLayout";
import axios from "axios";
import {CANCEL_DELIVERY_REQUESTS} from "../../apis/apis";
import {toast} from "react-toastify";
import SingleShipmentCard from "../../components/dashboard/SingleShipmentCard";
import SingleAddressCard from "../../components/dashboard/SingleAddressCard";
import DeliveryFessCard from "../../components/dashboard/DeliveryFessCard";
import {useTranslation} from "react-i18next";

export const SingleDeliveryRequest = () => {
    const requests = useSelector(state => state.deliveryRequests.requests);
    const dispatch = useDispatch();
    const {id} = useParams();
    const [selectedRequest, setSelectedRequest] = useState({});
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const {t} = useTranslation()



    const CancelRequestHandler = async () => {
        try {
            await axios.get(
                CANCEL_DELIVERY_REQUESTS + `/${selectedRequest.id}`,
                {
                    headers: {
                        apiKey: process.env.REACT_APP_API_KEY,
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );

            toast.success('Request Canceled Successfully');
            navigate('/dashboard/delivery-requests');

        } catch (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                window.location.pathname = "/signin";
            } else {
                toast.error('Request Cancellation Failed');
            }

        }
    }

    useEffect(() => {
        dispatch(getDeliveryRequests());
    }, [dispatch]);

    useEffect(() => {
        let selectedRequests = requests?.filter(request => request.id == id);
        if (requests.length > 0) {
            setSelectedRequest(selectedRequests[0]);
        } else {
            setSelectedRequest(null)
        }
        setLoading(false);
    }, [id, requests]);
    return (
        <>
            {
                loading ? <Loading/> : (<>

                        <>
                            <div className="mt-5 pt-5 mb-4">
                                <h5 className={"mb-0"}>{t('shipmentsToDeliver')}</h5>
                            </div>
                            {
                                selectedRequest?.total_shipments?.map(item => <SingleShipmentCard
                                    classes={"mt-4"}
                                    wasilUserTrackId={item.wasil_user_track_id} date={item.date}
                                    totalWeight={item.total_weight} weightUnit={item.weight_unit}
                                    string={item.total_value}
                                    packagesCount={item.packages_count} boxCount={item.box_count}
                                    branch={item.branch}/>)
                            }

                            <div className="mt-5 pt-5 mb-4">
                                <h5 className={"mb-0"}>{t('deliveryAddress')}</h5>
                            </div>

                            {
                                selectedRequest &&

                                <SingleAddressCard address={selectedRequest.address} city={selectedRequest.city}
                                                   name={selectedRequest.name} phone={selectedRequest.phone}/>
                            }


                            <div className="mt-5 pt-5 mb-4">
                                <h5 className={"mb-0"}>{t('deliveryFee')}</h5>
                            </div>

                            {
                                selectedRequest &&

                                <DeliveryFessCard fees={selectedRequest.delivery_fees}
                                                  currency={'IQD'}/>
                            }

                            {selectedRequest?.status === 'pending' &&

                                <div className={"d-flex align-items-center justify-content-end mt-5"}>
                                    <button
                                        onClick={() => CancelRequestHandler(selectedRequest?.id)}
                                        className="delivery-requests-btn btn"
                                        style={{color: "#6612d3"}}
                                    >
                                        {t('cancelRequest')}
                                    </button>
                                </div>
                            }
                        </>
                    </>

                )
            }
        </>
    )
}
