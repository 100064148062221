import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import SignInForm from "../../components/auth/SignInForm";
import ForgotPasswordForm from "../../components/auth/ForgotPasswordForm";

function ForgotPassword() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          {t("wasil")} | {t("forgotPassword")}
        </title>
      </Helmet>
      <Header />
    <ForgotPasswordForm />
    </>
  );
}

export default ForgotPassword;
