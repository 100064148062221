import boxImg from "../assets/svgs/package.svg";
import React, {useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import PromocodeModal from "./PromocodeModal";
import {PromoCode} from "./PromoCode";

export const SearchBar = ({packsCount, searchEmit, hasPromo, withPromo = false, withCount = false}) => {
    const {t} = useTranslation();




    return (
        <div className={"row d-flex align-items-center justify-content-center"}>
            <div className={`col-12 ${withPromo && 'col-md-8 col-lg-9'} pr-md-0`}>
                <div className="new-search-header-top-flex">
                    {withCount && <div className="d-flex align-items-center">

                        <img src={boxImg} alt={t('boxImg')} loading="lazy" width={"50px"}/>
                        <h4 className="text-nowrap">
                            {" "}
                            <Trans
                                i18nKey="package3"
                                values={{
                                    expr: packsCount
                                }}
                                components={{
                                    s: <strong/>
                                }}
                            />
                        </h4>
                    </div>}
                    <div className={`new-box-searhc  ${!withCount && 'ml-0'}`}>
                        <span className="bx bx-search"></span>
                        <input
                            type="text"
                            placeholder={t("searchHere")}
                            onChange={(e) => searchEmit(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            {withPromo &&
                <PromoCode hasPromo={hasPromo} />
            }
        </div>
    )
}