import React, {useState} from "react";
import axios from "axios";
import {NavLink} from "react-router-dom";
import {CHECK_OTP_API, SEND_OTP_API} from "../../../apis/apis";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";


function SendOtp({setStep}) {
    const {t} = useTranslation();
    const [otp, setOtp] = useState("");

    const otpChangeHandler = (e) => {
        setOtp(e.target.value)
    }



    const verifyOtpHandler = async (e) => {
        e.preventDefault();
        try {
            const body = {
                email: localStorage.getItem('reset_email'),
                otp,
            };
            const {data} = await axios.post(CHECK_OTP_API, body, {
                headers: {
                    apiKey: process.env.REACT_APP_API_KEY,
                },
            });

            if(data.success){
                toast.success(t('verifiedSuccessfully'));
                setStep(2)
            }
        } catch (error) {
            if(error?.response?.status === 401){
                toast.error(t('wrongOtp'));
            }else {
                toast.error(error.message)
            }
        }
    };

    const resendOtpHandler = async () => {
        try {
            const body = {
                email: localStorage.getItem('reset_email'),
            };
            const {data} = await axios.post(SEND_OTP_API, body, {
                headers: {
                    apiKey: process.env.REACT_APP_API_KEY,
                },
            });

            if (data.success) {
                toast.success(t('emailSentSuccessfully'))
            }else{
                toast.error(t('wrongEmail'));
            }
        } catch (error) {
            if(error?.response?.status === 422){
                toast.error(t('wrongEmail'));
            }else {
                toast.error(error.message)
            }
        }
    }

    return (

        <form className="content-log" onSubmit={verifyOtpHandler}>
            <h4>
                {t("ForgotPassword")} |{" "}
                <NavLink style={{color: "#8A23FE"}} to="/signin">
                    {t("signIn")}
                </NavLink>
            </h4>
            <p className="line-log"></p>

            <div className="input-log">
                <input
                    type="number"
                    min={1}
                    minLength={6}
                    maxLength={6}
                    placeholder={t("otp")}
                    onChange={otpChangeHandler}
                    value={otp}
                    required
                />
            </div>

            <div className="text-right">
                <p style={{color:"#8A23FE", cursor:"pointer"}} className={"small font-weight-bold"} onClick={resendOtpHandler}>{t('resendEmail')}</p>
            </div>


            <button type="submit" className="but-log-in" disabled={otp?.length != 6}>
                {t("verify")}
            </button>
        </form>

    )
}

export default SendOtp