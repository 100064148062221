import Modal from "react-modal";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {PROMO_CODE_API, REMOVE_PROMO_CODE_API} from "../apis/apis";
import {toast} from "react-toastify";
import {getPackagesInbox} from "../redux/features/inboxSlice";
import {useDispatch} from "react-redux";
import {Trans, useTranslation} from "react-i18next";

const PromocodeModal = ({isOpened, setIsOpened, applied = null}) => {
    const [promoCode, setPromoCode] = useState('');
    const [wrongCode, setWrongCode] = useState(null)
    const dispatch = useDispatch();
    const {t} = useTranslation()

    useEffect(() => {
            setPromoCode(applied)
    }, [applied]);

    const sendPromoCode = async (e) => {
        e.preventDefault();
        if (!applied) {
            try {
                const {data} = await axios.post(
                    PROMO_CODE_API,
                    {
                        code: promoCode,
                        country: localStorage.getItem("currentBranchCode"),
                    },
                    {
                        headers: {
                            apiKey: process.env.REACT_APP_API_KEY,
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                );
                toast.success(data.message);
                dispatch(
                    getPackagesInbox({
                        country_code: localStorage.getItem("currentBranchCode"),
                        status: null,
                    })
                );
                setPromoCode("");
                setIsOpened(false);
            } catch (error) {
                setWrongCode(error.response?.data.message)
                setPromoCode("");
            }
        } else {
            const {data} = await axios.post(
                REMOVE_PROMO_CODE_API,
                {
                    country: localStorage.getItem("currentBranchCode"),
                },
                {
                    headers: {
                        apiKey: process.env.REACT_APP_API_KEY,
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            toast.success(data.message);
            dispatch(
                getPackagesInbox({
                    country_code: localStorage.getItem("currentBranchCode"),
                    status: null,
                })
            );
            setPromoCode('')
            setIsOpened(false);
        }
    };


    return (
        <>
            <Modal
                isOpen={isOpened}
                style={{
                    overlay: {zIndex: "999999999999999", background: 'rgba(0,0,0,0.33)'},
                    content: {
                        // height: '60vh',
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '10px',
                        padding: 0
                    }
                }}
                contentLabel="Example Modal">
                <div className={"promoModal_header"}>


                    <button id="closeBtn" className={"text-right mb-3 btn btn-link"} onClick={() => setIsOpened(false)}>


                        <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z"
                                fill="#bebdbd"/>
                        </svg>
                    </button>


                    <p className="promo_title">
                     <span className={"mr-3"}>

                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24"
                             fill="#7604fe">
                            <path
                                d="M21.3 10.8394C21.69 10.8394 22 10.5294 22 10.1394V9.20938C22 5.10938 20.75 3.85938 16.65 3.85938H7.35C3.25 3.85937 2 5.10938 2 9.20938V9.67938C2 10.0694 2.31 10.3794 2.7 10.3794C3.6 10.3794 4.33 11.1094 4.33 12.0094C4.33 12.9094 3.6 13.6294 2.7 13.6294C2.31 13.6294 2 13.9394 2 14.3294V14.7994C2 18.8994 3.25 20.1494 7.35 20.1494H16.65C20.75 20.1494 22 18.8994 22 14.7994C22 14.4094 21.69 14.0994 21.3 14.0994C20.4 14.0994 19.67 13.3694 19.67 12.4694C19.67 11.5694 20.4 10.8394 21.3 10.8394ZM9 8.87938C9.55 8.87938 10 9.32938 10 9.87938C10 10.4294 9.56 10.8794 9 10.8794C8.45 10.8794 8 10.4294 8 9.87938C8 9.32938 8.44 8.87938 9 8.87938ZM15 15.8794C14.44 15.8794 13.99 15.4294 13.99 14.8794C13.99 14.3294 14.44 13.8794 14.99 13.8794C15.54 13.8794 15.99 14.3294 15.99 14.8794C15.99 15.4294 15.56 15.8794 15 15.8794ZM15.9 9.47937L9.17 16.2094C9.02 16.3594 8.83 16.4294 8.64 16.4294C8.45 16.4294 8.26 16.3594 8.11 16.2094C7.82 15.9194 7.82 15.4394 8.11 15.1494L14.84 8.41938C15.13 8.12938 15.61 8.12938 15.9 8.41938C16.19 8.70938 16.19 9.18937 15.9 9.47937Z"
                                fill="#7604fe"/>
                        </svg>
                     </span>

                        <p className={applied && "font-weight-bolder"}><Trans
                            i18nKey="wasilDiscountCode"
                            components={{
                                b: <br/>
                            }}
                        /></p>
                    </p>

                </div>

                <div className={"promoModal_body"}>
                    <p>{t('writeDownTheDiscountCodeBelowToSaveCostsOnYourNext')}</p>
                    <input
                        type="text"
                        value={promoCode}
                        disabled={applied}
                        className={"text-center"}
                        style={applied && {
                            color: '#7604fe',
                            fontWeight: 900,
                            fontSize: "1.8rem",
                        }}
                        onChange={e => setPromoCode(e.target.value)}
                    />
                    {applied ?
                        (
                            <button onClick={sendPromoCode} className="new-box-searhc text-center w-auto"
                                    style={{color: "#000"}}>
                            <span className={"mx-auto d-flex align-items-center "}
                                  style={{fontWeight: 900}}>{t('removeCode')}</span>
                            </button>
                        ) :
                        (<button onClick={sendPromoCode} className="new-box-searhc text-center w-auto"
                                 style={{color: "#7604fe"}}>
                            <span className={"mx-auto d-flex align-items-center "}
                                  style={{fontWeight: 900}}>{t('apply')}</span>
                        </button>)}
                </div>
                {wrongCode && <div className="promoModal_action">
                    <p>{wrongCode}</p>
                </div>}


            </Modal>
        </>
    )
}


export default PromocodeModal;