import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import { GENERAL_SETTINGS } from "../apis/apis";

function Rules() {
  const { t } = useTranslation();
  const [info, setInfo] = useState(null);

  useEffect(() => {
    const getInfo = async () => {
      try {
        const { data } = await axios.get(GENERAL_SETTINGS, {
          headers: {
            apiKey: process.env.REACT_APP_API_KEY,
          },
        });
        setInfo(data.data);
      } catch (error) {
        console.error("Error fetching the info:", error);
      }
    };
    getInfo();
  }, []);

  return (
    <div className="container">
      {info && info.terms ? (

           <div className="container my-5 bg-light p-3 card"> 
           <div className="row">
             <div className="col-12 card-body">
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(info.terms) }} />
             </div>
           </div>
         </div>

      ) : (
        <p>Loading...</p>
      )}
    </div>
  ); 
}

export default Rules;
