import React, {useState} from "react";
import CalculatorBackground from "../components/shipping-calculator/CalculatorBackground";
import FormCalculate from "../components/shipping-calculator/FormCalculate";
import Service from "../components/shipping-calculator/Service";
import ServiceTow from "../components/shipping-calculator/ServiceTow";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import MainLayout from "../layouts/MainLayout";
import Loading from "../components/Loading";
import CalculationResults from "../components/shipping-calculator/CalculationResults";

function ShippingCalculator() {
  const { t } = useTranslation();
    const [result, setResult] = useState(null);
    const [locations, setLocations] = useState(null);
    const [loading, setLoading] = useState(false);
    const [unites, setUnites] = useState({});

    return (
    <>
      <Helmet>
        <title>
          {t("wasil")} | {t("shippingCalc")}{" "}
        </title>
      </Helmet>
      <MainLayout>
        <CalculatorBackground />
          {
              loading && <Loading />
          }

          {
            !result? <section className="form-calculate" style={{
              display: loading && 'none'
            }}><FormCalculate unites={unites} setUnites={setUnites} setLoading={setLoading} setLocations={setLocations} setResult={setResult}/></section>: <section><CalculationResults results={result} unites={unites} location={locations} setResults={setResult}/></section>
          }


        <Service />
        <ServiceTow />
      </MainLayout>
    </>
  );
}

export default ShippingCalculator;
