import "./App.css";
import {BrowserRouter, Navigate, Route, Routes, useNavigate} from "react-router-dom";
import Home from "./pages/Home";
import Rules from "./pages/Rules";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ShippingCalculator from "./pages/ShippingCalculator";
import Memberships from "./pages/Memberships";
import Inbox from "./pages/dashboard/Inbox";
import Shipments from "./pages/dashboard/Shipments";
import Archive from "./pages/dashboard/Archive";
import MyBranch from "./pages/dashboard/MyBranch";
import ShippingAddress from "./pages/dashboard/ShippingAddress";
import Outbox from "./pages/dashboard/Outbox";
import Pictures from "./pages/dashboard/Pictures";
import EditPrice from "./pages/dashboard/EditPrice";
import ReturnPackage from "./pages/dashboard/ReturnPackage";
import Settings from "./pages/dashboard/Settings";
import {List as HomeAddressList} from "./pages/dashboard/address/List";
import {Create as CreateAddress} from "./pages/dashboard/address/Create";
import {Edit as EditAddress} from "./pages/dashboard/address/Edit";
import {DeliveryRequests} from "./pages/dashboard/DeliveryRequests";
import {SingleDeliveryRequest} from "./pages/dashboard/SingleDeliveryRequest";
import React, {useEffect, useState} from "react";
import {MyRequests} from "./pages/dashboard/MyRequests";
import {ReadyShipments} from "./components/ReadyShipments";
import {AccountInformationSettings} from "./pages/AccountInformationSettings";
import {MemberShips} from "./pages/dashboard/MemberShips";
import {ChangePassword} from "./pages/dashboard/ChangePassword";
import {ChangeMemberShip} from "./pages/dashboard/ChangeMemberShip";
import {ChangeAddress} from "./pages/dashboard/ChangeAddress";
import {useTranslation} from "react-i18next";
import {Wallet} from "./pages/dashboard/Wallet";
import ForgotPassword from "./pages/auth/ForgotPassword";

function App() {
    const {i18n} = useTranslation()
    const params = new URLSearchParams(document.location.search)
    const router = useNavigate();
    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("user")));
    const [token, setToken] = useState(localStorage.getItem("token"));

    useEffect(() => {
        let language = localStorage.getItem('APP_LANGUAGE') ?? 'en';
        i18n.changeLanguage(language);

        document
            .getElementsByTagName("html")[0]
            .setAttribute("lang", i18n.language);

        if(language === 'ar'){
            document.body.setAttribute("class", "arbic");
        }else{
            document.body.removeAttribute("class");
        }

    }, [localStorage]);

    useEffect(() => {

        if(params.get('token') && params.get('user')){
            localStorage.setItem("token", params.get('token'))
            localStorage.setItem("user", params.get('user'))
            setUserData(JSON.parse(localStorage.getItem("user")));
            setToken( localStorage.getItem("token"));
            router('/dashboard/inbox')
        }

    }, [params]);


    return (

            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/rules" element={<Rules/>}/>
                <Route
                    path="/signin"
                    element={token && userData ? <Navigate to="/"/> : <SignIn/>}
                />
                <Route
                    path="/signup"
                    element={token && userData ? <Navigate to="/"/> : <SignUp/>}
                />
                <Route
                    path="/forgot-password"
                    element={token && userData ? <Navigate to="/"/> : <ForgotPassword/>}
                />
                <Route path="/calculate" element={<ShippingCalculator/>}/>
                <Route path="/membership" element={<Memberships/>}/>
                <Route
                    path="/dashboard/my-branch"
                    element={token && userData ? <MyBranch/> : <Navigate to="/signin"/>}
                />
                <Route
                    path="/dashboard/inbox"
                    element={token && userData ? <Inbox/> : <Navigate to="/signin"/>}
                />
                <Route
                    path="/dashboard/outbox"
                    element={token && userData ? <Outbox/> : <Navigate to="/signin"/>}
                />
                <Route
                    path="/dashboard/pictures/:id"
                    element={token && userData ? <Pictures/> : <Navigate to="/signin"/>}
                />
                <Route
                    path="/dashboard/edit-price/:id"
                    element={token && userData ? <EditPrice/> : <Navigate to="/signin"/>}
                />
                <Route
                    path="/dashboard/return-package/:id"
                    element={token && userData ? <ReturnPackage/> : <Navigate to="/signin"/>}
                />
                <Route
                    path="/dashboard/shipments"
                    element={
                        token && userData ? <Shipments/> : <Navigate to="/signin"/>
                    }
                />
                <Route
                    path="/dashboard/archive"
                    element={token && userData ? <Archive/> : <Navigate to="/signin"/>}
                />
                <Route
                    path="/dashboard/shipping-address"
                    element={
                        token && userData ? <ShippingAddress/> : <Navigate to="/signin"/>
                    }
                />
                <Route
                    path="/dashboard/delivery-requests"
                    element={
                        token && userData ? <DeliveryRequests/> : <Navigate to="/signin"/>
                    }
                >
                    <Route path={""} index={true} element={
                        token && userData ? <MyRequests/> : <Navigate to="/signin"/>}/>

                    <Route path={"create"} index={true} element={
                        token && userData ? <ReadyShipments/> : <Navigate to="/signin"/>}/>


                    <Route path={"view/:id"} index={true} element={
                        token && userData ? <SingleDeliveryRequest/> : <Navigate to="/signin"/>}/>
                </Route>

                <Route
                    path="/dashboard/delivery-requests/show/:id"
                    element={
                        token && userData ? <SingleDeliveryRequest/> : <Navigate to="/signin"/>
                    }
                />
                <Route
                    path="/dashboard/delivery-address"
                    element={
                        token && userData ? <HomeAddressList/> : <Navigate to="/signin"/>
                    }
                />
                <Route
                    path="/dashboard/delivery-address/create"
                    element={
                        token && userData ? <CreateAddress/> : <Navigate to="/signin"/>
                    }
                />
                <Route
                    path="/dashboard/delivery-address/edit/:id"
                    element={
                        token && userData ? <EditAddress/> : <Navigate to="/signin"/>
                    }
                />
                <Route
                    path="/dashboard/settings"
                    element={
                        token && userData ? <Settings/> : <Navigate to="/signin"/>
                    }
                >
                    <Route path={"account"} index={true} element={token && userData ? <AccountInformationSettings/> :
                        <Navigate to="/signin"/>}></Route>
                    <Route path={"memberships"}
                           element={token && userData ? <MemberShips/> : <Navigate to="/signin"/>}></Route>
                    <Route path={"account/change"} index={true}
                           element={token && userData ? <ChangeAddress/> : <Navigate to="/signin"/>}></Route>
                    <Route path={"memberships/change/:hubID"}
                           element={token && userData ? <ChangeMemberShip/> : <Navigate to="/signin"/>}></Route>
                    <Route path={"password"}
                           element={token && userData ? <ChangePassword/> : <Navigate to="/signin"/>}></Route>
                    <Route path={"wallet"}
                           element={token && userData ? <Wallet/> : <Navigate to="/signin"/>}></Route>
                </Route>

                <Route path="/*" element={<Navigate to="/"/>}/>

            </Routes>


    );
}

export default App;
