import React, { useState} from "react";
import {useTranslation} from "react-i18next";

import PackagesList from "../dashboard/outbox/PackagesList";
import boxImg from "../../assets/images/img-box1.svg";
import {SearchBar} from "../SearchBar";

const DeliveredShipments = ({ deliveredShipments}) => {
    const [search, setSearch] = useState(null);

    const {t} = useTranslation();



    const content = () => {

        if (!search) {
            return deliveredShipments?.map((item, i) => <PackagesList item={item} i={i}/>);
        } else {
            const filterPackagesByName = deliveredShipments?.filter((packs) =>
                packs.name.toLowerCase().includes(search.trim().toLowerCase())
            )
                .map((item, i) => <PackagesList item={item} i={i}/>);
            const filterPackagesByTrackId = deliveredShipments?.filter((packs) =>
                packs.wasil_user_track_id
                    .toLowerCase()
                    .includes(search.trim().toLowerCase())
            )
                .map((item, i) => <PackagesList item={item} i={i}/>);

            if (filterPackagesByName.length > 0) {
                return filterPackagesByName;
            } else if (filterPackagesByTrackId.length > 0) {
                return filterPackagesByTrackId;
            }
        }
        // Result Search Not Found
        return null;
    }

    return (
        <>
            <SearchBar packsCount={content()?.length ?? 0} searchEmit={setSearch} />
            {content()}
        </>
    )
}

export default DeliveredShipments;