import React, {useEffect, useState} from "react";
import testProfileImg from "../../assets/images/tst-image1.jpg";
import {NavLink} from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import {useTranslation} from "react-i18next";
import DashboardHeader from "../../components/dashboard/DashboardHeader";
import DashboardLayout from "../../layouts/DashboardLayout";
import DeliveredShipments from "../../components/archive/DeliveredShipments";
import {useDispatch, useSelector} from "react-redux";
import {getPackagesArchive} from "../../redux/features/ArchiveSlice";
import Loading from "../../components/Loading";
import ReturnedPacks from "../../components/archive/ReturnedPacks";

function Archive() {
    const [showNotifications, setShowNotifications] = useState(false);
    const {t} = useTranslation();
    const [toggleTab, setToggleTab] = useState(1);
    const {deliveredShipments, returnedPacks} = useSelector((state) => state.archive);
    const {loading} = useSelector((state) => state.archive);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPackagesArchive({
            country_code: localStorage.getItem("currentBranchCode"),
        }))
    }, [dispatch]);

    return (
        <DashboardLayout>
            <div className="home_content">
                {/*<Breadcrumb>*/}
                {/*    <li className="breadcrumb-item">*/}
                {/*        <NavLink to="/">{t("home")}</NavLink>*/}
                {/*    </li>*/}
                {/*    <li className="breadcrumb-item">*/}
                {/*        <NavLink to="/dashboard">{t("dashboard")}</NavLink>*/}
                {/*    </li>*/}
                {/*    <li className="breadcrumb-item active" aria-current="page">*/}
                {/*        {t("Archive")}*/}
                {/*    </li>*/}
                {/*</Breadcrumb>*/}

                <DashboardHeader/>
                <nav className="mt-5 mx-auto">
                    <div className="nav nav-tabs justify-content-center border-0" id="nav-tab" role="tablist">
                        <button
                            className={`nav-link ${toggleTab == 1 && "active color-main"}`}
                            id="nav-home-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-home"
                            type="button"
                            role="tab"
                            aria-controls="nav-home"
                            aria-selected="true"
                            onClick={() => setToggleTab(1)}
                        >
                            {t('deliveredShipments')}
                        </button>
                        <button
                            className={`nav-link ${toggleTab == 2 && "active color-main"}`}
                            id="nav-profile-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-profile"
                            type="button"
                            role="tab"
                            aria-controls="nav-profile"
                            aria-selected="false"
                            onClick={() => setToggleTab(2)}
                        >
                            {t('returnedPackages')}
                        </button>
                    </div>
                </nav>

                <div
                    id="viewNotoficationToggle"
                    className="view-toggle-notofication"
                    style={{display: `${showNotifications ? "block" : "none"}`}}
                >
                    <div className="card-item-ntofiation">
                        <article className="art-flex-info-notofication">
                            <img src={testProfileImg} alt={t('testProfileImg')} loading="lazy"/>
                            <div className="name-and-time-noto">
                                <h5>UserName</h5>
                                <span>{t('timeAndData')}</span>
                            </div>
                        </article>
                        <p>
                            {t('loremIpsumDolorSitAmetConsecteturAdipisicingElitSi')}
                        </p>
                    </div>
                    <hr/>
                    <div className="card-item-ntofiation">
                        <article className="art-flex-info-notofication">
                            <img src={testProfileImg} alt={t('testProfileImg')} loading="lazy"/>
                            <div className="name-and-time-noto">
                                <h5>UserName</h5>
                                <span>{t('timeAndData')}</span>
                            </div>
                        </article>
                        <p>
                            {t('loremIpsumDolorSitAmetConsecteturAdipisicingElitSi')}
                        </p>
                    </div>
                    <hr/>
                    <div className="card-item-ntofiation">
                        <article className="art-flex-info-notofication">
                            <img src={testProfileImg} alt={t('testProfileImg')} loading="lazy"/>
                            <div className="name-and-time-noto">
                                <h5>UserName</h5>
                                <span>{t('timeAndData')}</span>
                            </div>
                        </article>
                        <p>
                            {t('loremIpsumDolorSitAmetConsecteturAdipisicingElitSi')}
                        </p>
                    </div>
                </div>
                {loading ? <Loading /> :
                    toggleTab === 1?
                <DeliveredShipments loading={loading} deliveredShipments={deliveredShipments}/>
                        : <ReturnedPacks packages={returnedPacks}/>
                }
            </div>
        </DashboardLayout>
    );
}

export default Archive;
