import React, {useState} from "react";
import axios from "axios";
import {Link, NavLink, useNavigate} from "react-router-dom";
import {REST_PASSWORD_API} from "../../../apis/apis";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";


function ResetPassword() {
    const {t} = useTranslation();
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const passwordChangeHandler = (e) => {
        setPassword(e.target.value)
    }
    const passwordConfirmationChangeHandler = (e) => {
        setPasswordConfirmation(e.target.value)
    }



    const changePasswordHandler = async (e) => {
        e.preventDefault();
        if(password !== passwordConfirmation){
            return toast.error(t('passwordDoesntMatch'));
        }
        try {
            const body = {
                password: password,
                password_confirmation: passwordConfirmation,
                email: localStorage.getItem('reset_email')
            };
            const {data} = await axios.post(REST_PASSWORD_API, body, {
                headers: {
                    apiKey: process.env.REACT_APP_API_KEY,
                },
            });

            if (data.success) {
                toast.success(t('passwordChangedSuccessfully'));
                localStorage.removeItem('reset_email');
                setTimeout(() => {
                    navigate('/signin')
                }, 3000)
            }
        } catch (error) {
            toast.error(error.response.data.message);
        }
    };


    return (

        <form className="content-log" onSubmit={changePasswordHandler}>
            <h4>
                {t("ForgotPassword")} |{" "}
                <NavLink style={{color: "#8A23FE"}} to="/signin">
                    {t("signIn")}
                </NavLink>
            </h4>
            <p className="line-log"></p>

            <div className="input-log">
                <input
                    type={`${showPassword ? "text" : "password"}`}
                    placeholder={t("newPassword")}
                    onChange={passwordChangeHandler}
                    value={password}
                    required
                />
            </div>


            <div className="input-log">
                <input
                    type={`${showPassword ? "text" : "password"}`}
                    placeholder={t("ReType") +' '+ t("newPassword")}
                    onChange={passwordConfirmationChangeHandler}
                    value={passwordConfirmation}
                    required
                />
            </div>

            <div className="flex-log-chek-and-foregt">
                <div className="log-ckeckbox ms-1">
                    <input
                        type="checkbox"
                        onChange={() => setShowPassword(!showPassword)}
                    />
                    <span className={"ms-2"}> {t("showPassword")}</span>
                </div>

            </div>


            <button type="submit" className="but-log-in">
                {t("changePassword")}
            </button>
        </form>

    )
}

export default ResetPassword