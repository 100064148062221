import Modal from "react-modal";
import React from "react";
import truckSvg from "../assets/svgs/truck.svg"
import isVolum from "../assets/svgs/isVolum.svg"
import {Trans, useTranslation} from "react-i18next";

const CalculatorModal = ({isOpened, type, setIsOpen}) => {
    const {t} = useTranslation()
    return (
        <>
            <Modal
                isOpen={isOpened}
                onRequestClose={() => setIsOpen(false)}
                style={{
                    overlay: {zIndex: "999999999999999", background: 'rgba(0,0,0,0.33)'},
                    content: {
                        // height: '60vh',
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '10px',
                        padding: 0
                    }
                }}>

                <div id="header" className="card shadow-el mb-0 p-3">
                    <div className="card-body">
                        <div className="d-flex justify-content-between w-100 rounded-lg py-3 px-4" style={{
                            background: type === 'deliver' ? 'rgba(129, 21, 255, 0.05)' : 'rgba(243, 167, 53, 0.05)',
                            color: type === 'deliver' ? 'rgba(129, 21, 255, 1)' : 'rgba(243, 167, 53, 1)',
                        }}>
                            {type === 'deliver' && <>
                                <span className={"font-weight-bold"}>{t('HomeDeliveryFee')}</span>
                                <img src={truckSvg} alt="truckSvg"/>
                            </>
                            }

                            {
                                type === 'overWeight' && <>
                                    <span className={"font-weight-bold"}>{t('OversizePackage')}</span>
                                    <img src={isVolum} alt="isVolum"/>
                                </>
                            }
                            {
                                type === 'Weight' && <>
                                    <span className={"font-weight-bold"}>{t('Vweight')}</span>
                                    <img src={isVolum} alt="isVolum"/>
                                </>
                            }
                        </div>

                        <div className="p-4 mt-2">
                            {type === 'deliver' && <>
                            {t('deliverMsg')}
                            </>
                            }

                            {
                                type === 'Weight' && <Trans i18nKey={"vWeightMsg"}>
                                </Trans>
                            }

                            {
                                type === 'overWeight' && <Trans i18nKey={"overWeightMsg"}>

                                </Trans>
                            }


                            {type !== 'deliver' &&
                                <div className={"d-flex align-items-center justify-content-center"}>

                                    <a href={'#termsAndConditions'} onClick={() => setIsOpen(false)} className={"span-one p-2 mx-auto mt-4 shadow-el w-75"} style={{
                                        backgroundColor: "#fff",
                                        color: 'rgba(129, 21, 255, 1)'
                                    }}>
                                        {t('RulesAndRegulations')}
                                    </a>

                                </div>
                            }


                        </div>
                    </div>
                </div>

            </Modal>
        </>
    )
}


export default CalculatorModal;