// CrispChat.js
import { useEffect } from 'react';

// const CrispChat = () => {
//   useEffect(() => {
//     // Replace 'YOUR_WEBSITE_ID' with your actual Crisp Website ID
//     window.$crisp = [];
//     window.CRISP_WEBSITE_ID = "4b184114-0c38-4552-8502-81549fa87cb8";

//     (function () {
//       const d = document;
//       const s = d.createElement("script");
//       s.src = "https://client.crisp.chat/l.js";
//       s.async = 1;
//       d.getElementsByTagName("head")[0].appendChild(s);
//     })();
//   }, []);

//   return null; // This component does not render anything
// };


const CrispChat = ({ websiteId = "4b184114-0c38-4552-8502-81549fa87cb8" }) => {
  useEffect(() => {
    if (!window.$crisp) {
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = websiteId;

      (function() {
        const d = document;
        const s = d.createElement('script');
        s.src = 'https://client.crisp.chat/l.js';
        s.async = true;
        d.getElementsByTagName('head')[0].appendChild(s);
      })();
    }

    // if (window.$crisp) {
    //   // Setting user email and nickname
    //   // window.$crisp.push(['set', 'user:email', [userEmail]]);
    //   // window.$crisp.push(['set', 'user:nickname', [userNickname]]);
    // }
  }, [websiteId]);

  return null; // This component does not render anything
};

export default CrispChat;

  
