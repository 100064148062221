const InfoCard = ({icon, title, children}) => {
    return (
        <div className={"card mb-0 shadow-el p-2"}>
            <div className="card-body">
                <div className="d-flex flex-column flex-md-row align-items-center" style={{
                    gap: "2rem"
                }}>
                    <img src={icon} alt={title} width={"80px"}/>
                    <div className="d-flex flex-column">
                        <h4 className={"font-weight-bold"} style={{
                            color:"rgba(129, 21, 255, 1)"
                        }}>{title}</h4>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}


export default InfoCard;