import Modal from "react-modal";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Trans, useTranslation} from "react-i18next";
import insuranceSvg from "../assets/svgs/insurance.svg"
import {fetchInsurance} from "../redux/features/InsuranceSlice";
import {toast} from "react-toastify";
import axios from "axios";
import {Activate_INSURANCE, DEActivate_INSURANCE} from "../apis/apis";

const InsuranceModal = ({isOpened, setIsOpened}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation()
    const {info: insuranceInfo} = useSelector(state => state.insurance)
    const [amount, setAmount] = useState(null)
    const [error, setError] = useState(null)


    const deactivate = async () => {

            try {
                const {data} = await axios.post(DEActivate_INSURANCE, {
                    country_code: localStorage.getItem("currentBranchCode")
                }, {
                    headers: {
                        apiKey: process.env.REACT_APP_API_KEY,
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });

                if(data.success){
                    toast.success('Successfully deactivated insurance')
                    setTimeout(() => dispatch(fetchInsurance({
                        country_code: localStorage.getItem("currentBranchCode")
                    })), 500)

                }else {
                    toast.error('Failed to deactivate insurance')

                }
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    window.location.pathname = "/signin";

                } else {
                    toast.error('Failed to deactivate insurance')
                }
            }finally {
                setAmount(null)
                setError(null)
                setIsOpened(false)
            }

    }

    const activate = async () => {

        try {
            let body = {
                country_code: localStorage.getItem("currentBranchCode")
            };

            if (insuranceInfo.hub.toLowerCase() !== 'usa') {
                if(amount <= 0 || amount === null){
                    return setError('Please add an amount')
                }
                body = {
                    ...body,
                    amount
                }
            }

            const {data} = await axios.post(Activate_INSURANCE, {
                ...body
            }, {
                headers: {
                    apiKey: process.env.REACT_APP_API_KEY,
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            if(data.success){

            toast.success('Successfully activated insurance')
            setTimeout(() => dispatch(fetchInsurance({
                country_code: localStorage.getItem("currentBranchCode")
            })), 500)
            }else{
                toast.error('Insurance should be a valid number')
            }

        } catch (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                window.location.pathname = "/signin";

            } else {
                toast.error('Insurance should be a valid number')

            }
        }finally {
            setIsOpened(false)
            setAmount(null)
            setError(null)
        }

    }


    return (
        <>
            <Modal
                isOpen={isOpened}
                style={{
                    overlay: {zIndex: "999999999999999", background: 'rgba(0,0,0,0.33)'},
                    content: {
                        // height: '60vh',
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '10px',
                        padding: 0
                    }
                }}
                contentLabel="Example Modal">
                <div className={"promoModal_header"}>


                    <button id="closeBtn" className={"text-right mb-3 btn btn-link"} onClick={() => setIsOpened(false)}>


                        <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z"
                                fill="#bebdbd"/>
                        </svg>
                    </button>


                    <p className="promo_title">
                     <span className={"mx-3"}>

                        <img src={insuranceSvg} width={100} alt=""/>
                     </span>

                        <p className={insuranceInfo?.status && "font-weight-bolder"}><Trans
                            i18nKey="wasilInsurance"
                            components={{
                                b: <br/>
                            }}
                        /></p>
                    </p>


                </div>

                <div className={"promoModal_body px-0"}>
                    <h2 className={"font-weight-bold mb-5"} style={{fontSize: "3rem", color:"#7604fe"}}>{insuranceInfo?.value}<span className={"text-muted"} style={{fontSize:"1.5rem"}}>{insuranceInfo?.symbol}</span></h2>
                    <p className={"text-justify"}>{t('insuranceText')}</p>
                    {insuranceInfo?.hub.toLowerCase() !== 'usa' && insuranceInfo?.status !== 1 && <input
                        type="text"
                        value={amount ?? ''}
                        placeholder={t("valueForInsurance")}
                        disabled={insuranceInfo?.status}
                        style={{
                            color: '#7604fe',
                            fontWeight: 900,
                            fontSize: "1.8rem",
                            textAlign: "center"
                        }}
                        onChange={e => setAmount(e.target.value)}
                    />}
                    {insuranceInfo?.status === 1 ?
                        (
                            <button onClick={deactivate} className="new-box-searhc text-center w-auto"
                                    style={{color: "#000"}}>
                            <span className={"mx-auto d-flex align-items-center "}
                                  style={{fontWeight: 900}}>{t('deactivate')}</span>
                            </button>
                        ) :
                        (
                            <button onClick={activate} className="new-box-searhc text-center w-auto"
                                    style={{color: "#7604fe"}}>
                                <span className={"mx-auto d-flex align-items-center "}
                                      style={{fontWeight: 900}}>{t('activate')}</span>
                            </button>
                        )
                    }
                </div>
                {error && <div className="promoModal_action mt-0">
                    <p>{error}</p>
                </div>}


            </Modal>
        </>
    )
}


export default InsuranceModal;