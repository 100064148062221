import React, {useEffect, useState} from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import truckSvg from "../../assets/svgs/truck.svg";
import {useTranslation} from "react-i18next";
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import backArrowSvg from "../../assets/svgs/backarrow.svg";
import {goBackStep, resetSteps} from "../../redux/features/DeliveryRequestsSlice";
import {useDispatch, useSelector} from "react-redux";

export const DeliveryRequests = () => {
    const {t} = useTranslation();
    const [shown, setShown] = useState(true);
    const step = useSelector(state => state.deliveryRequests.NewRequestStep)
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();


    useEffect(() => {
        if (location.pathname.includes('create') || location.pathname.includes('view')) {
            setShown(false)
        } else {
            setShown(true);
        }
        if (location.pathname === '/dashboard/delivery-requests') {
            dispatch(resetSteps());
            localStorage.removeItem('selectedShipments');
        }

    }, [location, dispatch]);


    const backHandler = () => {
        if (location.pathname.includes('create')) {
            if (step !== 1) {
                dispatch(goBackStep())
            } else {
                navigate(-1)
            }
        } else {
            navigate(-1)
        }
    }
    return (
        <DashboardLayout>
            <div className="home_content">
                <div className="home-delivery-flex">

                    <div className="row w-100">
                        <div className="col-12 col-md-6 d-flex align-items-center ">

                            <div className="box-image-address">
                                <img src={truckSvg} alt="pics" width={42}/>
                            </div>
                            <div className="content-address-flex" style={{lineHeight: "0.5", marginTop: "10px"}}>
                                <p className={"font-weight-bold mb-0 pb-0"}>{t("Request")}</p>
                                <h2 className={"font-weight-bolder mt-0 pt-0 mb-0"}>{t("Home Delivery").toUpperCase()}</h2>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 d-flex align-items-center justify-content-end">

                            {shown ? <Link
                                    to={"create"}
                                    className="delivery-requests-btn btn w-100"
                                    style={{color: "#7100FE"}}
                                >
                                    {t("Make a Home Delivery Request")}
                                </Link> :
                                <button onClick={backHandler}
                                        className={"btn btn-link text-black-50 back-arrow mr-4"}
                                        style={{fontSize: "2rem"}}>
                                    <img src={backArrowSvg} alt="" width={"40"}/>
                                </button>
                            }
                        </div>
                    </div>


                </div>

                <Outlet/>
            </div>
        </DashboardLayout>
    )
}