import React, {useState} from "react";
import axios from "axios";
import {NavLink, useNavigate} from "react-router-dom";
import {SEND_OTP_API} from "../../../apis/apis";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";


function SendEmail({setStep}) {
    const {t} = useTranslation();
    const [email, setEmail] = useState("");
    const [btnStatus, setBtnStatus] = useState(true);


    const recaptchaHandler = (response) => {
        if (response) {
            setBtnStatus(false)
        } else {
            setBtnStatus(true)
        }
    }

    const recaptchaExpiredHandler = () => {
        setBtnStatus(true)
    }


    const handleLogin = async (e) => {
        e.preventDefault();
        setBtnStatus(true);
        try {
            const body = {
                email,
            };
            const {data} = await axios.post(SEND_OTP_API, body, {
                headers: {
                    apiKey: process.env.REACT_APP_API_KEY,
                },
            });

            if (data.success) {
                toast.success(t('emailSentSuccessfully'))
                localStorage.setItem('reset_email', email)
                setStep(1)
            }else{
                toast.error(t('wrongEmail'));
            }
        } catch (error) {
            if(error?.response?.status === 422){
                toast.error(t('wrongEmail'));
            }else {
                toast.error(error.message)
            }
        }finally {
            setBtnStatus(false)
        }
    };
    return (

        <form className="content-log" onSubmit={handleLogin}>
            <h4>
                {t("ForgotPassword")} |{" "}
                <NavLink style={{color: "#8A23FE"}} to="/signin">
                    {t("signIn")}
                </NavLink>
            </h4>
            <p className="line-log"></p>

            <div className="input-log">
                <input
                    type="email"
                    placeholder={t("email")}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                />
            </div>

            <div className="f-flex align-items-center justify-content-center mt-3">
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                    hl={localStorage.getItem('APP_LANGUAGE') ?? 'en'}
                    onChange={recaptchaHandler}
                    onExpired={recaptchaExpiredHandler}
                />

            </div>


            <button type="submit" className="but-log-in" disabled={btnStatus}>
                {t("next")}
            </button>
        </form>

    )
}

export default SendEmail