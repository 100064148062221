import React, {useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import PackagesList from "../dashboard/inbox/PackagesList";
import boxImg from "../../assets/images/img-box1.svg";

const ReturnedPacks = ({packages}) => {
    const [search, setSearch] = useState(null);

    const {t} = useTranslation();
    const content = () => {

        if (!search) {
            return packages?.map((item, i) => <PackagesList item={item} i={i}/>);
        } else {
            const filterPackagesBySender = packages
                ?.filter((packs) =>
                    packs.sender.toLowerCase().includes(search.trim().toLowerCase())
                )
                .map((item, i) => <PackagesList item={item} i={i}/>);
            const filterPackagesByTrackNumber = packages
                ?.filter((packs) =>
                    packs.track_number.toLowerCase().includes(search.trim().toLowerCase())
                )
                .map((item, i) => <PackagesList item={item} i={i}/>);

            if (filterPackagesBySender.length > 0) {
                return filterPackagesBySender;
            } else if (filterPackagesByTrackNumber.length > 0) {
                return filterPackagesByTrackNumber;
            }
            // Result Search Not Found
            return null;
        }
    }
    return (
        <>
            <div className="new-search-header-top-flex">
                <img src={boxImg} alt={t('boxImg')} loading="lazy"/>
                <h4>
                    {" "}
                    <Trans
                        i18nKey="package2"
                        values={{
                            length: content()?.length
                        }}
                        components={{
                            s: <strong/>
                        }}
                    />
                </h4>
                <div className="new-box-searhc">
                    <span className="bx bx-search"></span>
                    <input
                        type="text"
                        placeholder={t("searchHere")}
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                    />
                </div>
            </div>
            {content()}
        </>
    );

}

export default ReturnedPacks;