import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getMyMemberships, getPlans, subscribe} from "../../redux/features/settingsSlice";
import {useNavigate, useParams} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import {useTranslation} from "react-i18next";

export const ChangeMemberShip = () => {
    const [selected, setSelected] = useState(null);
    const [pay, setPay] = useState(null);
    const {plans} = useSelector((state) => state.settings);
    const {hubID} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const {t} = useTranslation();

    useEffect(() => {
        if (hubID) {
            dispatch(getPlans(hubID));
        }
    }, [hubID, dispatch]);

    const changePlanHandler = async () => {
        if(!selected){
            return toast.error('Please Select Membership Plan')
        }

        if(!pay){
            return toast.error('Please Select Payment Cycle')
        }
        const subscribeData = {
            cycle: pay === 1? 'monthly': 'yearly',
            plan_id: selected,
        };
        await dispatch(subscribe(subscribeData));
        await dispatch(getMyMemberships());
        toast.success('Your Membership Changed Successfully');
        setTimeout(() => {
            navigate(-1)
        }, 3000)
    };


    return (
        <div style={{marginTop: "2.2rem"}}>
            <ToastContainer />
            <h5 style={{fontWeight: 500}} className={"mb-3"}>{t('selectMembershipFor')} {plans[0]?.hub_name}</h5>
            {plans.map(plan => (
                <div className={"mt-5 row d-flex align-items-center pl-0"}>
                    <div className="col-1">

                        <button
                            onClick={() => {
                                setSelected(plan.id)
                                setPay(null)
                            }}
                            className={`shadow-el bg-white ${selected == plan.id && 'selected'} mr-4`}
                            style={{width: "42px", height: "42px", borderRadius: "10px", border: "10px solid #fff"}}>

                        </button>
                    </div>
                    <div className="col-3">

                        <div className="content-suite-header-content-flex shadow-el rounded-lg">
                            <article className="part-left-title-content-suite w-100">
                                <div className="d-flex align-item-start justify-content-between w-100">
                                    <article className="row w-100 flex-wrap">
                                        <div className={"col-12 request-first"}>
                                            <p className="font-weight-bolder text-dark mb-0">{plan.name}</p>
                                        </div>
                                    </article>
                                </div>
                            </article>
                        </div>
                    </div>
                    {selected === plan.id && <div className="col">


                        <article className="part-left-title-content-suite w-100">
                            <div className="d-flex align-item-start justify-content-between w-100">
                                <article className="row w-100 flex-wrap">
                                    <div className={"col-6 "}>
                                        <div className="d-flex align-items-center">

                                            <button
                                                onClick={() => setPay(1)}
                                                className={`shadow-el bg-white ${pay === 1 && 'selected'} mr-4`}
                                                style={{
                                                    width: "42px",
                                                    height: "42px",
                                                    borderRadius: "10px",
                                                    border: "10px solid #fff"
                                                }}>

                                            </button>
                                            <div>
                                                <p className="font-weight-bolder text-dark mb-0">{t('payMonthly')}</p>
                                                <p className="font-weight-bolder mb-0"
                                                   style={{color: '#747474'}}>{plan.price_monthly} {t('iqd')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"col-6 "}>
                                        <div className="d-flex align-items-center">

                                            <button
                                                onClick={() => setPay(2)}
                                                className={`shadow-el bg-white ${pay === 2 && 'selected'} mr-4`}
                                                style={{
                                                    width: "42px",
                                                    height: "42px",
                                                    borderRadius: "10px",
                                                    border: "10px solid #fff"
                                                }}>

                                            </button>
                                            <div>
                                                <p className="font-weight-bolder text-dark mb-0">{t('payYearly')}</p>
                                                <p className="font-weight-bolder mb-0"
                                                   style={{color: '#747474'}}>{plan.price_yearly} {t('iqd')}</p>
                                            </div>
                                        </div>
                                    </div>

                                </article>
                            </div>
                        </article>
                    </div>}
                </div>
            ))}


            <div className={"w-100 text-right mt-5"}>
                <button
                    onClick={changePlanHandler}
                    className="delivery-requests-btn btn" style={{color: "#6612d3"}}>{t('changePlan')}</button>
            </div>
        </div>
    )
}