import React, {useEffect, useState} from "react";
import Sidebar from "../../components/dashboard/Sidebar";
import boxImg from "../../assets/images/img-box1.svg";
import {getPackageInbox} from "../../redux/features/getPackageSlice";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate, useParams} from "react-router-dom";
import Loading from "../../components/Loading";
import {editPrice} from "../../redux/features/editPriceSlice";
import {ToastContainer, toast} from "react-toastify";
import priceSvg from "../../assets/svgs/price.svg"
import {useTranslation} from "react-i18next";
import backArrowSvg from "../../assets/svgs/backarrow.svg";
import uploadSvg from "../../assets/svgs/upload.svg";
import {FileUploader} from "react-drag-drop-files";


function EditPrice() {
    const [inputs, setInputs] = useState({});
    const [invoices, setInvoices] = useState([]);

    const packageDetails = useSelector((state) => state.package.package);
    const {loading} = useSelector((state) => state.package);
    const {message} = useSelector((state) => state.updatePrice);
    const {t} = useTranslation();
    const [fileLabel, setFileLabel] = useState('')
    const navigate = useNavigate();
    const {id} = useParams();
    const dispatch = useDispatch();


    useEffect(() => {
        let names = [];
        for (let i = 0; i < invoices.length; i++) {
            names.push(invoices[i]?.name);
        }
        let name = names.join(' , ');

        setFileLabel(name)

    }, [invoices]);


    useEffect(() => {
        dispatch(getPackageInbox(id));
    }, []);
    useEffect(() => {
        if (message.success) {
            toast.success(message.message);

            setTimeout(function () {
                window.location.pathname = "/dashboard/inbox";
            }, 3000);
        }
    }, [message]);

    function handleChange(e) {
        const name = e.target.name;
        const value = e.target.value;
        setInputs((values) => ({...values, [name]: value}));
    }

    const submitData = (e) => {
        e.preventDefault();
        if (Object.keys(inputs).length === 0) {
            toast.error("Please edit one Item at least");
        } else if (invoices.length === 0) {
            toast.error("Please Upload Invoice");
        } else {
            let newData = {
                package_id: id,
                data: JSON.stringify(inputs),
                images: invoices,
            };
            dispatch(editPrice(newData));
        }
    };

    if (loading) {
        return <Loading/>;
    }


    const handleBack = () => {
        navigate(-1);
    }

    return (
        <div id="admin-page">
            <ToastContainer/>
            <div className="holder-dashboard">
                <Sidebar/>
                <div className="home_content">
                    <div className="home-delivery-flex">

                        <div className="addres-header-flex align-items-center">
                            <div className="box-image-address">
                                <img src={priceSvg} alt="pics" width={50}/>
                            </div>
                            <div className="content-address-flex"  style={{lineHeight: "0.5", marginTop:"10px"}}>
                                <p className={"font-weight-bold mb-0 pb-0"}>{t("Edit Your Items")}</p>
                                <h2 className={"font-weight-bolder mt-0 pt-0 mb-0"}>{t("Value").toUpperCase()}</h2>
                            </div>
                        </div>

                        <button onClick={handleBack} className={"btn btn-link text-black-50 back-arrow mr-3"}
                                style={{fontSize: "2rem"}}>
                            <img src={backArrowSvg} alt="" width={"40"}/>
                        </button>
                    </div>
                    <h6 className={"my-5 prag-return font-weight-bold"}>
                        Insert the actual value of your items below and upload the{" "}
                        <span style={{color: '#850efe'}}>ORIGINAL INVOICE</span> of the pakage. our team will review
                        the uploaded document and approve the request.
                    </h6>
                    <div
                        id="newTable"
                        className="newTable"
                        style={{display: "block !important", margin: "50px 0px;", border: "1px solid transparent", borderRadius: "10px !important"}}
                    >
                        <div className="content-suite-header-content-flex" style={{zIndex: 100000000000000000, border: "1px solid transparent", borderRadius:"10px!important"}}>
                            <div className="box-with-shadow"></div>
                            <article className="part-left-title-content-suite">
                                {/*<img src={boxImg} alt="box img" loading="lazy"/>*/}
                                <article>
                                    <h5>
                                        {packageDetails?.sender} - <span>{packageDetails?.track_number}</span>
                                    </h5>
                                    <p>{packageDetails?.date && new Date(packageDetails?.date).toLocaleDateString(undefined, {
                                        month: "long",
                                        day: "2-digit",
                                        year: 'numeric'
                                    })}</p>
                                </article>
                            </article>

                        </div>
                        <div
                            id="newTable"
                            className="newTable"
                            style={{
                                position: 'relative',
                                zIndex: 1,
                            }}
                        >
                            <div className="box-with-shadow"></div>
                            <div className="table-responsive" style={{zIndex: 100000, position: "relative"}}>

                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>Item Description</th>
                                        <th className={"text-center"}>Quantity</th>
                                        <th className={"text-center"}>Value per unit</th>
                                        <th className={"text-center"}>Total value</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {packageDetails?.items?.map((item, i) => (
                                        <tr key={i}>
                                            <td data-lable="Item Description"  style={{paddingLeft: "46px"}}>{item.category}</td>
                                            <td data-lable="Quantity" className={"text-center"}>{item.quantity}</td>
                                            <td data-lable="Value per unit" className={"text-center"}>
                                                <input
                                                    className="edit-price px-1"
                                                    type="number"
                                                    defaultValue={item.unit_value}
                                                    name={item?.id}
                                                    value={inputs?.item?.id}
                                                    min={0}

                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </td>

                                            <td data-lable=" Total value" className={"text-center"}>$ {item.total_value}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <FileUploader
                        name="file"
                        classes={"wrapper-upload mb-3 mt-4"}
                        handleChange={(file) => setInvoices(file)}
                        multiple={true}
                    >
                        <div className="content">
                            <div className="icon">
                                <img src={uploadSvg} alt="upload" style={{width: "100px"}}/>
                            </div>
                            <div className="text">{invoices.length == 0? 'Drag and drop your files here' : fileLabel}</div>
                        </div>
                        <div id="cancel-btn">
                            <i className="fas fa-times"></i>
                        </div>
                        <div className="file-name">img1.jfif</div>
                    </FileUploader>
                    <form
                        className="flex-button-uploadImage"
                        onSubmit={(e) => submitData(e)}
                    >
                        <article>
                            <div className="ps-sm-3 text-center text-sm-start mx-3">
                                <input
                                    type="file"
                                    multiple
                                    id="uploadImgProfile"
                                    className="d-none"
                                    onChange={(e) => setInvoices(e.target.files)}
                                />

                                <button type="button" id="custom-btn">
                                    <label className={"p-0 m-0"} htmlFor="uploadImgProfile" role="button">
                                        Select Files
                                    </label>
                                </button>
                            </div>

                            {/* <input id="default" type="file" hidden="" /> */}
                        </article>
                        <article className="subMit mr-3">
                            <button type="submit">Submit</button>
                        </article>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EditPrice;
