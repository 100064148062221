import React, {useEffect, useState} from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {changeAddress, getMyMemberships, getUserInfo,} from "../../redux/features/settingsSlice";
import {CHANGE_UER_PASSWORD} from "../../apis/apis";

import {ToastContainer} from "react-toastify";
import settingSvg from "../../assets/svgs/settings.svg";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import backArrowSvg from "../../assets/svgs/backarrow.svg";

function Settings() {
    const navigate = useNavigate();
    const location = useLocation();
    const {t} = useTranslation();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMyMemberships());
        dispatch(getUserInfo());
    }, [dispatch]);

    const handleBack = () => {
        navigate(-1);
    }

    return (
        <DashboardLayout>
            <ToastContainer/>
            <div className="home_content">
                <div className="home-delivery-flex mb-1">
                <div className="addres-header-flex align-items-center">
                    <div className="box-image-address">
                        <img src={settingSvg} alt={t('pics')} width={50}/>
                    </div>
                    <div className="content-address-flex" style={{lineHeight: "0.5", marginTop: "10px"}}>
                        <p className={"font-weight-bold mb-0 pb-0"}>{t("Account")}</p>
                        <h2 className={"font-weight-bolder mt-0 pt-0 mb-0"}>{t("SETTINGS").toUpperCase()}</h2>
                    </div>
                </div>
                    {location.pathname.includes('change') &&

                    <button onClick={handleBack} className={"btn btn-link text-black-50 back-arrow mr-4"}
                            style={{fontSize: "2rem"}}>
                        <img src={backArrowSvg} alt="" width={"40"}/>
                    </button>
                    }
                </div>
                <Outlet/>
            </div>
        </DashboardLayout>);
}

export default Settings;
