import React, {useState} from "react";
import {toast} from "react-toastify";
import axios from "axios";
import {CHANGE_UER_PASSWORD} from "../../apis/apis";
import {useTranslation} from "react-i18next";

export const ChangePassword = () => {
    const [oldPass, setOldPass] = useState('');
    const [newPass, setNewPass] = useState('');
    const [confirmation, setConfirmation] = useState('');
    const [t] = useTranslation()
    const handleChangePassword = async () => {
        if (newPass.trim() === '' || oldPass.trim() === '' || confirmation.trim() === '') {
            return toast.error("Please Enter All Fields");
        }

        if (newPass !== confirmation) {
            return toast.error("Password does not match confirmation.");
        }

        try {
            const {data} = await axios.post(
                CHANGE_UER_PASSWORD,
                {
                    old: oldPass,
                    password: newPass,
                    password_confirmation: confirmation
                },
                {
                    headers: {
                        apiKey: process.env.REACT_APP_API_KEY,
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );

            if (data.success === true) {
                toast.success('Password changed successfully')
            }

        } catch (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                window.location.pathname = "/signin";
            } else {
                toast.error('Invalid Password');
            }
        }
    }

    return (
        <div style={{marginTop: "2.2rem"}}>
            <h5 style={{fontWeight: 500}} className={"mb-3"}>{t('changePassword')}</h5>

                <div className="content-suite-header-content-flex shadow-el rounded-lg mt-5 col-12 col-md-6 mx-auto">
                <article className="part-left-title-content-suite w-100">
                    <div className="d-flex align-item-start justify-content-between w-100">
                        <article className="row w-100 flex-wrap">
                            <div className={"col-12 my-3"}>
                                    <p className={"pb-0 mb-0 font-weight-bold"} style={{color: "#6612d3"}}>{t('oldPassword')}</p>
                                    <input type="password" className={"form-control my-2 rounded-lg"} onChange={e => setOldPass(e.target.value)}/>
                                <p className={"pb-0 mb-0 font-weight-bold mt-3"} style={{color: "#6612d3"}}>{t('newPassword')}</p>
                                    <input type="password" className={"form-control my-2 rounded-lg"} onChange={e => setNewPass(e.target.value)}/>
                                <p className={"pb-0 mb-0 font-weight-bold mt-3"} style={{color: "#6612d3"}}>{t('confirmPassword')}</p>
                                    <input type="password" className={"form-control my-2 rounded-lg"} onChange={e => setConfirmation(e.target.value)}/>

                                <div className={"w-100 text-right mt-5"}>
                                    <button
                                        onClick={handleChangePassword}
                                        className="delivery-requests-btn btn" style={{color: "#6612d3"}}>{t('changePassword')}</button>
                                </div>
                            </div>

                        </article>
                    </div>
                </article>
            </div>

        </div>
    )
}