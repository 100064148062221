import React, {useEffect, useState} from "react";
import {changeAddress} from "../../redux/features/settingsSlice";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const ChangeAddress = () => {
    const {userInfo} = useSelector((state) => state.settings);
    const dispatch = useDispatch();
    const [address, setAddress] = useState('');
    const navigate = useNavigate();
    const {t} = useTranslation();

    const handleAddress = async () => {
        dispatch(changeAddress(address));
        toast.success('Your Address Changed Successfully');
        setTimeout(() => {
            navigate(-1)
        }, 3000)
    }


    useEffect(() => {
        setAddress(userInfo?.address)
    }, [userInfo]);
    return (
        <div style={{marginTop: "2.2rem"}}>
            <h5 style={{fontWeight: 500}} className={"mb-3"}>{t('changeAddress')}</h5>

            <div className="content-suite-header-content-flex shadow-el rounded-lg mt-5 col-12 col-md-6 mx-auto">
                <article className="part-left-title-content-suite w-100">
                    <div className="d-flex align-item-start justify-content-between w-100">
                        <article className="row w-100 flex-wrap">
                            <div className={"col-12 my-3"}>
                                <p className={"pb-0 mb-0 font-weight-bold"} style={{color: "#6612d3"}}>{t('address')}</p>
                                <textarea className={"form-control my-2 rounded-lg"}  rows={5} value={address} onChange={e => setAddress(e.target.value)}/>

                                <div className={"w-100 text-right mt-5"}>
                                    <button
                                        onClick={handleAddress}
                                        className="delivery-requests-btn btn" style={{color: "#6612d3"}}>{t('saveAddress')}</button>
                                </div>
                            </div>

                        </article>
                    </div>
                </article>
            </div>

        </div>
    )
}