import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import InsuranceModal from "./InsuranceModal";
import insuranceSvg from "../assets/svgs/insurance.svg"
import insuranceWhiteSvg from "../assets/svgs/insuranceWhite.svg"
import {useSelector} from "react-redux";

export const Insurance = () => {
    const [showModal, setShowModal] = useState(false)
    const {t} = useTranslation();
    const {info: insuranceInfo} = useSelector(state => state.insurance)
    return (

        <div className={"col-12 col-md-4 col-lg-3"}>
            {insuranceInfo?.status === 1 ?(<button className="new-box-searhc text-center w-100"
                     style={{color: "#fff", fontWeight: 600}}
                     onClick={() => setShowModal(true)} style={{background: '#7604fe', color: '#fff'}}>
                    <span className={"mx-auto d-flex align-items-center"}>

                        <img src={insuranceWhiteSvg} alt=""/>
                        <span className={"mx-2"}>
                            {t('Insurance')}
                        </span>
                    </span>
            </button>)
:
                (<button className="new-box-searhc text-center w-100"
                         style={{color: "#7604fe", fontWeight: 600}}
                         onClick={() => setShowModal(true)}
                >
                    <span className={"mx-auto d-flex align-items-center"}>

                      <img src={insuranceSvg} alt=""/>


                        <span className={"mx-2"}>
                            {t('Insurance')}
                        </span>
                    </span>
                </button>)
}

            <InsuranceModal setIsOpened={setShowModal} isOpened={showModal}/>
        </div>)
}