import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {saveItems} from "../../../redux/features/saveItemsSlice";
import {toast, ToastContainer} from "react-toastify";
import {BeatLoader} from "react-spinners";
import weightImg from "../../../assets/svgs/weight.svg"
import cbmImg from "../../../assets/svgs/cbm.svg"

function PackagesList({item: pack, i}) {
    const [showTable, setShowTable] = useState(false);
    const [packageId, setPackageId] = useState(null);
    const [inputs, setInputs] = useState({});
    const loading = useSelector((state) => state.uploadInvoice.loading);
    const loadingSendPrice = useSelector((state) => state.saveItems.loading);
    const [sumTotalValue, setSumTotalValue] = useState();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const {t} = useTranslation();

    function handleChange(e, id) {
        const name = e.target.name;
        const value = e.target.value;
        setInputs((values) => ({...values, [name]: value}));
        setPackageId(id);
    }

    const submitData = async (e, package_id, count) => {
        e.preventDefault();
        const file = e.target.files[0];
        if (e.target.files.length !== 0 && file.size < 1000000) {
            const reader = new FileReader();

            reader.onloadend = async () => {
                const body = {
                    package_id,
                    label: reader.result.toString(),
                    extention: file.name.split(".").pop(),
                    data: JSON.stringify(inputs),
                };


                if (Object.keys(inputs).length !== count) {
                    toast.error("Please edit all Items");
                } else {

                    await dispatch(saveItems(body));
                    navigate(0);
                }
            };
            reader.readAsDataURL(file);
        } else {
            toast.error("The image size is large");
        }

    };


    useEffect(() => {
        let total = 0;
        pack?.items?.map((packItem) => total = total + packItem.total_value);
        setSumTotalValue(total)
    }, [pack]);

    return (
        <section className="new-content-suite mt-5 package" key={i}>
            <ToastContainer/>
            <div className="content-suite-header-content-flex" style={{zIndex: 100000000000000000}}>
                <div className="box-with-shadow"></div>
                <article className="part-left-title-content-suite justify-content-sm-center">
                    {/*<img src={boxImg} alt="box img" loading="lazy"/>*/}
                    <article>
                        <h5>
                            {pack.sender} - <span>{pack.track_number}</span>
                        </h5>
                        <p className={"mb-0"}>{pack.date && new Date(pack.date).toLocaleDateString(undefined, {
                            month: "long",
                            day: "2-digit",
                            year: 'numeric'
                        })}</p>
                    </article>
                </article>

                <article className="part-right-title-content-suite position-relative justify-content-sm-center">

                    <div className={"text-right"}>
                        <p>{t("packageStatus")}</p>
                        {
                            pack.DVRequired ? <h5>{t('actionNeeded')}</h5> : <h5>{t(pack.status.toUpperCase())}</h5>
                        }

                    </div>

                    <div className={"d-flex align-items-center justify-content-center"}>
                        {pack.edit_price_status !== 0 && <div className="prat-tight-iocn">
                            <div className="box-part-right-icon"
                                 style={{
                                     padding: "7px",
                                     background: pack.edit_price_status == 1 ? 'transparent' : pack.edit_price_status == 2 ? "#329a63" : '#cf1022',
                                     border: pack.edit_price_status == 1 ? '2px solid #7100FE' : "transparent 2px solid"
                                 }}>
                                <svg width="25" height="25" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M15.78 2H8.22C4.44 2 3.5 3.01 3.5 7.04V18.3C3.5 20.96 4.96 21.59 6.73 19.69L6.74 19.68C7.56 18.81 8.81 18.88 9.52 19.83L10.53 21.18C11.34 22.25 12.65 22.25 13.46 21.18L14.47 19.83C15.19 18.87 16.44 18.8 17.26 19.68C19.04 21.58 20.49 20.95 20.49 18.29V7.04C20.5 3.01 19.56 2 15.78 2ZM14.84 9.99L14.34 10.5H14.33L11.3 13.53C11.17 13.66 10.9 13.8 10.71 13.82L9.36 14.02C8.87 14.09 8.53 13.74 8.6 13.26L8.79 11.9C8.82 11.71 8.95 11.45 9.08 11.31L12.12 8.28L12.62 7.77C12.95 7.44 13.32 7.2 13.72 7.2C14.06 7.2 14.43 7.36 14.84 7.77C15.74 8.67 15.45 9.38 14.84 9.99Z"
                                        fill={pack.edit_price_status == 1 ? '#7100FE' : '#fff'}/>
                                </svg>
                            </div>

                        </div>}


                        <div className="prat-tight-iocn">
                            {pack.items[0].is_dangerous === 1 && (
                                <div className="box-part-right-icon ic-right-bg-one">
                                    <span className="bx bxs-hot" style={{fontSize: "2rem"}}></span>
                                </div>
                            )}


                            {
                                pack.DVRequired ? (
                                    <div className="box-part-right-icon p-2" style={{backgroundColor: "#cf1022"}}>
                                        <svg width="25" height="25" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z"
                                                fill="#fff"/>
                                        </svg>
                                    </div>) : (<div className="box-part-right-icon"
                                                    style={{backgroundColor: pack.is_returned == 1 ? '#020202' : '#7100FE'}}>
                                    <span className="bx bx-check" style={{fontSize: "2rem"}}></span>
                                </div>)
                            }


                        </div>
                    </div>


                </article>
            </div>
            {pack.problems.length !== 0 && (
                <div
                    id="newTable"
                    className="newTable"
                    style={{
                        display: `block`,
                    }}
                >


                    <table border="1">
                        <thead>
                        <tr>
                            <th>{t('problemType')}</th>
                            <th>{t('problemDetails')}</th>
                        </tr>
                        </thead>
                        <tbody style={{backgroundColor: "#F8D7DA"}}>
                        {pack?.problems?.map((items, i) => (
                            <tr key={i} className="bolder">
                                {localStorage.getItem("currentBranchCode") === "usa" && (
                                    <>
                                        <td className={"p-4"} data-label="Problem Type">
                                            <span className="bx bxs-hot text-danger"></span>{" "}
                                            {items.problem_type}
                                        </td>
                                        <td className={"p-4"}>{items.problem_details}</td>
                                    </>
                                )}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}


            <div
                id="newTable"
                className="newTable"
                style={{
                    position: 'relative',
                    zIndex: 1,
                    display: `${showTable && pack.id === pack.id ? "block" : "none"}`,
                }}
            >
                <div className="box-with-shadow"></div>
                <div className="table-responsive" style={{zIndex: 100000, position: "relative"}}>

                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th className={"w-50"}>{t("itemDescription")}</th>
                            <th style={{textAlign: 'center'}}>{t("quantity")}</th>
                            {localStorage.getItem("currentBranchCode") === "usa" && (
                                <>
                                    <th style={{textAlign: 'center'}}>{t('valuePerUnit')}</th>
                                    <th style={{textAlign: 'center'}} className={"last-col"}>{t('totalValue')}</th>
                                </>
                            )}
                        </tr>
                        </thead>
                        <tbody>
                        {pack.items.map((items, i) => (
                            <tr key={i}>
                                <td data-label="Item Description" style={{paddingLeft: "46px"}}>{items.category}</td>
                                <td style={{textAlign: "center"}} data-label="Quantity">{items.quantity}</td>
                                {pack.DVRequired !== 1 &&
                                    localStorage.getItem("currentBranchCode") === "usa" && (
                                        <>
                                            <td style={{textAlign: "center"}}
                                                data-label="Value per unit">$ {parseFloat(items.unit_value).toFixed(2)}</td>
                                            <td style={{textAlign: "center"}}
                                                data-label=" Total value"
                                                className={"last-col td"}>$ {parseFloat(items.total_value).toFixed(2)}</td>
                                        </>
                                    )}
                                {pack.DVRequired === 1 &&
                                    localStorage.getItem("currentBranchCode") === "usa" && (
                                        <>
                                            <td data-label="Value per unit" style={{textAlign: "center"}}>
                                                <input
                                                    onWheel={event => event.preventDefault()}
                                                    className="edit-price px-1"
                                                    id={items.id}
                                                    type="number"
                                                    name={items?.id}
                                                    min={0}
                                                    value={inputs?.item?.id}
                                                    onChange={(e) => handleChange(e, pack?.id)}

                                                />
                                            </td>
                                            <td data-label=" Total value" className={"text-center last-col td"}>
                                                $ {parseFloat(document.getElementById(`${items?.id}`) &&
                                                items.quantity *
                                                +document.getElementById(`${items?.id}`).value).toFixed(2)}
                                            </td>
                                        </>
                                    )}
                            </tr>
                        ))}


                        </tbody>
                    </table>
                </div>

                <div style={{backgroundColor: "#fff", zIndex: 9, position: "relative"}}>
                    {pack.DVRequired === 1 &&
                        localStorage.getItem("currentBranchCode") === "usa" && (
                            <>


                                <input
                                    type="file"
                                    id={`uploadImgProfile${pack.id}`}
                                    className="d-none"
                                    accept="image/*"
                                    onChange={(e) => submitData(e, pack.id, pack.items.length)}
                                />
                                <label
                                    htmlFor={`uploadImgProfile${pack.id}`}
                                    role="button"
                                    className="EditPrice btn my-3 px-4"
                                    style={{
                                        lineHeight: '34px',
                                        backgroundColor: '#fff',
                                        boxShadow: '0 5px 10px rgba(0, 0, 0, 0.1)',
                                        borderRadius: '15px',
                                        fontWeight: 600,
                                        margin: '0px 45px',
                                        color: '#cf1022'
                                    }}
                                >
                                    {loading ? <BeatLoader color="#fff"/> : "Upload Invoice"}
                                </label>
                            </>
                        )}
                </div>
            </div>

            <div className="card-edting-button-page">
                <div className="card-edting-part1">
                    <div className="part1-flex-weight-and-value">
                        <div className="part1-bg-icon">
                        {localStorage.getItem("currentBranchCode") === "uss" 
                        ? 
                        <img src={cbmImg} alt={"CBM"} width={"20px"}/> 
                        :
                        <img src={weightImg} alt={"weight"} width={"20px"}/>
                        }
                            
                            
                        </div>
                        <div className="part1-cotnent">
                        <p>
                            {localStorage.getItem("currentBranchCode") === "uss" ? t("CBM") : t("weight")}
                        </p>
                            <h5>
                                {pack.weight} {t(pack.weight_unit)}
                            </h5>
                        </div>
                    </div>

                    {sumTotalValue > 0 && <div className="part1-flex-weight-and-value">
                        <div className="part1-bg-icon">
                            <svg width="25" height="25" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11.2519 8.07812V10.9381L10.2419 10.5881C9.73187 10.4081 9.42188 10.2381 9.42188 9.36812C9.42188 8.65812 9.95188 8.07812 10.6019 8.07812H11.2519Z"
                                    fill="#fff"/>
                                <path
                                    d="M14.58 14.6286C14.58 15.3386 14.05 15.9186 13.4 15.9186H12.75V13.0586L13.76 13.4086C14.27 13.5886 14.58 13.7586 14.58 14.6286Z"
                                    fill="#fff"/>
                                <path
                                    d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.19C2 19.83 4.17 22 7.81 22H16.19C19.83 22 22 19.83 22 16.19V7.81C22 4.17 19.83 2 16.19 2ZM14.26 12C15.04 12.27 16.08 12.84 16.08 14.63C16.08 16.17 14.88 17.42 13.4 17.42H12.75V18C12.75 18.41 12.41 18.75 12 18.75C11.59 18.75 11.25 18.41 11.25 18V17.42H10.89C9.25 17.42 7.92 16.03 7.92 14.33C7.92 13.92 8.25 13.58 8.67 13.58C9.08 13.58 9.42 13.92 9.42 14.33C9.42 15.21 10.08 15.92 10.89 15.92H11.25V12.53L9.74 12C8.96 11.73 7.92 11.16 7.92 9.37C7.92 7.83 9.12 6.58 10.6 6.58H11.25V6C11.25 5.59 11.59 5.25 12 5.25C12.41 5.25 12.75 5.59 12.75 6V6.58H13.11C14.75 6.58 16.08 7.97 16.08 9.67C16.08 10.08 15.75 10.42 15.33 10.42C14.92 10.42 14.58 10.08 14.58 9.67C14.58 8.79 13.92 8.08 13.11 8.08H12.75V11.47L14.26 12Z"
                                    fill="#fff"/>
                            </svg>

                        </div>
                        <div className="part1-cotnent">
                            <p>{t("Value")}</p>
                            <h5>
                                {sumTotalValue} {pack.currency_code}
                            </h5>
                        </div>
                    </div>}
                </div>
                <div className="card-edting-part2">
                    <Link
                        to={`/dashboard/pictures/${pack.id}`}
                        className="viewPicture btn mb-2 mb-md-0"
                    >
                        {t("viewPictures")}
                    </Link>
                    {localStorage.getItem("currentBranchCode") === "usa" && (
                        <>
                            {pack.edit_price_status === 0 ? (
                                <Link
                                    className="EditPrice btn my-3"
                                    to={`/dashboard/edit-price/${pack.id}`}
                                >
                                    {t('editPrice')}
                                </Link>
                            ) : (
                                <button className="EditPrice btn" disabled>
                                    {pack.edit_price_status === 1 && t("Edit Value Requested")}
                                    {pack.edit_price_status === 2 && t("Value Approved")}
                                    {pack.edit_price_status === 3 && t("Value Rejected")}
                                </button>
                            )}

                        </>
                    )}
                    {pack.is_returned === 0 && (
                        <Link
                            to={`/dashboard/return-package/${pack.id}`}
                            className="ReturnPackage btn"
                        >
                            {t('returnPackage')}
                        </Link>
                    )}

                    <button
                        type="button"
                        id="TogglesIcon"
                        className={`bx ${
                            showTable ? "bx-chevron-up" : "bx-chevron-down"
                        } button-toggle-arrows`}
                        onClick={() => setShowTable(!showTable)}
                    ></button>
                </div>
            </div>


        </section>
    );
}

export default PackagesList;
