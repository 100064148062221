import React, {useEffect, useState} from "react";
import Loading from "./Loading";
import axios from "axios";
import {CREATE_DELIVERY_REQUEST, GET_READY_SHIPMENTS, GET_USER_ADDRESS} from "../apis/apis";
import SelectableShipment from "./SelectableShipment";
import {toast, ToastContainer} from "react-toastify";
import {Link, useNavigate} from "react-router-dom";
import SingleShipmentCard from "./dashboard/SingleShipmentCard";
import SingleAddressCard from "./dashboard/SingleAddressCard";
import DeliveryFessCard from "./dashboard/DeliveryFessCard";
import SelectableSingleAddress from "./dashboard/SelectableSingleAddress";
import {useDispatch, useSelector} from "react-redux";
import {goNextStep} from "../redux/features/DeliveryRequestsSlice";
import {getAddresses} from "../redux/features/deliveryAddressSlice";
import {useTranslation} from "react-i18next";

export const ReadyShipments = () => {
    const [loading, setLoading] = useState(true);
    const [shipments, setShipments] = useState([]);
    const [selectedShipments, setSelectedShipments] = useState(JSON.parse(localStorage.getItem('selectedShipments')) ?? []);
    const addresses = useSelector(state => state.deliveryAddresses.addresses)
    const [address, setAddress] = useState(null);
    const [selectedAdressDetails, setSelectedAdressDetails] = useState()
    const navigate = useNavigate();
    const step = useSelector(state => state.deliveryRequests.NewRequestStep)
    const dispatch = useDispatch();
    const {t} = useTranslation()


    const nextHandler = () => {
        dispatch(goNextStep())
    }


    const sendRequestHandler = async () => {
        try {
            const {data} = await axios.post(
                CREATE_DELIVERY_REQUEST,
                {
                    shipment_ids: selectedShipments.map(item => ({id: item.id, code: item.branch.code})),
                    address_id: address
                },
                {
                    headers: {
                        apiKey: process.env.REACT_APP_API_KEY,
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );

            toast.success('Request placed successfully');
            localStorage.removeItem('selectedShipments');
            setTimeout(() => {
                navigate('/dashboard/delivery-requests');
            }, 3000);
        } catch (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                window.location.pathname = "/signin";
            }
        }
    }
    const fetchShipments = async () => {
        try {
            const {data} = await axios.get(
                GET_READY_SHIPMENTS,
                {
                    headers: {
                        apiKey: process.env.REACT_APP_API_KEY,
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );

            setShipments(data.data);
            setLoading(false)
        } catch (error) {
            if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                window.location.pathname = "/signin";
            }
        }
    }

    useEffect(() => {
        dispatch(getAddresses());
        fetchShipments();
    }, [dispatch]);


    useEffect(() => {
        let addr = addresses.filter(item => item.id === address);
        setSelectedAdressDetails(addr[0])
    }, [address, addresses]);


    const checkHandler = (item) => {
        let exist = selectedShipments.filter(ship => ship.id === item.id);
        if (exist.length > 0) {
            let newSelected = selectedShipments.filter(ship => ship.id !== item.id)
            setSelectedShipments(old => [...newSelected]);
        } else {
            setSelectedShipments(old => [...old, item]);
        }

    }
    useEffect(() => {
        localStorage.setItem('selectedShipments', JSON.stringify(selectedShipments))
    }, [selectedShipments]);


    return (
        <div className="mt-5">
            <ToastContainer/>
            {step === 1 &&
                <>
                    <div className="mt-5 pt-5">

                        <h5 className={"mb-0 font-weight-bold"}>{t('selectTheShipmentsYouWantUsToDeliverToYourHome')}</h5>
                        <p className={"mb-0"}>{t('clickOnTheCheckboxToSelectAShipment')}</p>
                    </div>

                    <div className="mt-5">


                        {
                            loading === true ? <Loading/> :
                                shipments?.map((item, i) => <SelectableShipment key={i} item={item} i={i}
                                                                                checkHandler={checkHandler}
                                                                                selected={selectedShipments}/>)
                        }
                    </div>
                </>
            }


            {step === 2 &&
                <>
                    <div className="row w-100 pt-5">
                        <div className="col-6 d-flex align-items-center">
                            <div>
                                <h5 className={"mb-0 font-weight-bold"}>{t('selectTheAddress')}</h5>
                                <p className={"mb-0"}>{t('clickOnTheCheckboxToSelectTheAddress')}</p>
                            </div>
                        </div>
                        <div className="col-6 d-flex align-items-center justify-content-end p-0">
                            <Link
                                to={"/dashboard/delivery-address/create"}
                                className="delivery-requests-btn btn w-75 m-0"
                                style={{color: "#7100FE"}}
                            >
                                {t('addNewAddress')}
                            </Link>


                        </div>
                    </div>

                    <div className="mt-5">


                        {addresses?.map(item => <SelectableSingleAddress select={(id) => setAddress(id)} address={item}
                                                                         key={item.id}
                                                                         selected={address === item.id}/>)
                        }
                    </div>
                </>
            }

            {
                step === 3 &&

                <>
                    <div className="mt-5 pt-5 mb-4">
                        <h5 className={"mb-0"}>{t('shipmentsToDeliver')}</h5>
                    </div>
                    {

                        selectedShipments?.map(item => <SingleShipmentCard
                            key={item.id}
                            classes={"mt-4"}
                            wasilUserTrackId={item.wasil_user_track_id} date={item.date}
                            totalWeight={item.total_weight} weightUnit={item.weight_unit}
                            string={item.total_value}
                            packagesCount={item.packages_count} boxCount={item.box_count}
                            branch={item.branch}/>)
                    }

                    <div className="mt-5 pt-5 mb-4">
                        <h5 className={"mb-0"}>{t('deliveryAddress')}</h5>
                    </div>


                    <SingleAddressCard address={selectedAdressDetails?.address} city={selectedAdressDetails?.city}
                                       name={selectedAdressDetails?.name} phone={selectedAdressDetails?.phone}/>


                    <div className="mt-5 pt-5 mb-4">
                        <h5 className={"mb-0"}>{t('deliveryFee')}</h5>
                    </div>


                    <DeliveryFessCard fees={selectedAdressDetails?.city.delivery_fees}
                                      currency={selectedShipments[0]?.packages[0]?.currency_code}/>

                </>

            }
            {
                shipments?.length > 0 && step != 3 &&
                <div className="mt-5 text-right">

                    <button onClick={nextHandler} className="btn delivery-requests-btn" style={{color: "#6612d3"}}
                            disabled={(step === 1 && selectedShipments.length === 0) || (step === 2 && address == null)}>{t('next')}
                    </button>

                </div>
            }

            {
                shipments?.length > 0 && step === 3 &&
                <div className="mt-5 text-right">
                    <button onClick={sendRequestHandler} className="btn delivery-requests-btn"
                            style={{color: "#6612d3"}}
                            disabled={(step === 1 && selectedShipments.length === 0) || (step === 2 && address == null)}>{t('confirm')}
                    </button>
                </div>
            }

        </div>
    )
}