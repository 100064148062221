import React from "react";
import Hero from "../components/home/Hero";
import RulesComponent from "../components/Rules";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import MainLayout from "../layouts/MainLayout";

function Rules() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");
  const { t } = useTranslation();
 


  return (
    <>
      <Helmet>
        <title>
          {t("wasil")} | {t("Rules")}
        </title>
      </Helmet>
      <MainLayout>
        {/* <Hero userData={userData} token={token} /> */}
        <RulesComponent />
      </MainLayout>
    </>
  );
}

export default Rules;
