import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {Activate_INSURANCE, DEActivate_INSURANCE, GET_USER_INSURANCE} from "../../apis/apis";

const initialState = {
    info: null
}


export const fetchInsurance = createAsyncThunk('INSURANCE/FETCH_INSURANCE', async ({country_code}) => {
    try {
        const {data} = await axios.post(GET_USER_INSURANCE, {
            country_code
        }, {
            headers: {
                apiKey: process.env.REACT_APP_API_KEY,
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        return data.data;
    } catch (error) {
        if (error.response.status === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            window.location.pathname = "/signin";

        } else {
            console.log(error);
        }
    }
})

export const activateInsurance = createAsyncThunk('INSURANCE/ACTIVATE_INSURANCE', async ({body}) => {
    try {
        const {data} = await axios.post(Activate_INSURANCE, {
            ...body
        }, {
            headers: {
                apiKey: process.env.REACT_APP_API_KEY,
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        return data.data;
    } catch (error) {
        if (error.response.status === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            window.location.pathname = "/signin";

        } else {
            console.log(error);
        }
    }
})
export const deactivateInsurance = createAsyncThunk('INSURANCE/DEACTIVATE_INSURANCE', async ({country_code}) => {
    try {
        const {data} = await axios.post(DEActivate_INSURANCE, {
            country_code
        }, {
            headers: {
                apiKey: process.env.REACT_APP_API_KEY,
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        return data.data;
    } catch (error) {
        if (error.response.status === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            window.location.pathname = "/signin";

        } else {
            console.log(error);
        }
    }
})


const InsuranceSlice = createSlice({
    name: 'insurance',
    initialState,
    extraReducers: {
        [fetchInsurance.fulfilled]: (state, action) => {
            state.info = action.payload;
        }
    }
});


export default InsuranceSlice.reducer;