import {Link} from "react-router-dom";
import {SRC_IMG_API} from "../../apis/apis";
import * as PropTypes from "prop-types";
import React from "react";

function MemberShipCard(props) {
    return <div className="content-suite-header-content-flex shadow-el rounded-lg mt-4">
        <article className="part-left-title-content-suite w-100">
            <div className="d-flex align-item-start justify-content-between w-100">
                <article className="row w-100 flex-wrap">

                    <div className={"col-12 col-md-3 request-first"}>
                        <h5 style={{color: "#6612d3"}}>{props.membership.plan}</h5>
                        <p className={"pb-0 mb-0 font-weight-bold"}>{props.s} {props.membership.expire_at}</p>
                    </div>
                    <div className={"col-12 col-md-5"}>
                        <p className="text-dark mb-0">{props.membership.cycle?.toUpperCase()} {props.s1}{props.membership.price}</p>
                        <p className="text-dark mb-0 font-weight-bold">{props.membership.plan_type?.toUpperCase()} {props.s2}</p>
                    </div>
                    <div className={"col-12 col-md-3 d-flex align-items-center justify-content-end"}>
                        <Link
                            to={`change/${props.membership.hub_id}`}
                            className="delivery-requests-btn btn"
                        >{props.s3}</Link>
                    </div>
                    <div className={"col-12 col-md-1 address-last d-flex align-items-center justify-content-end"}>
                        <img
                            src={`${SRC_IMG_API}/${props.membership.icon}`}
                            alt={props.alt}

                            loading="lazy"
                        />
                    </div>


                </article>
            </div>
        </article>
    </div>;
}

MemberShipCard.propTypes = {
    membership: PropTypes.any,
    s: PropTypes.any,
    s1: PropTypes.any,
    s2: PropTypes.any,
    s3: PropTypes.any,
    alt: PropTypes.any
};


export default MemberShipCard;