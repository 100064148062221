import React, {useState} from "react";
import passwordIcon from "../../assets/images/unlock.svg";
import logImg from "../../assets/images/log.svg";
import axios from "axios";
import {Link, NavLink} from "react-router-dom";
import {LOGIN_API} from "../../apis/apis";
import {toast, ToastContainer} from "react-toastify";
import {useTranslation} from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import SendEmail from "./forgotPasswordSubForms/SendEmail";
import CheckOtp from "./forgotPasswordSubForms/CheckOtp";
import ResetPassword from "./forgotPasswordSubForms/ResetPassword";


function ForgotPasswordForm() {
    const {t} = useTranslation();
    const [step, setStep] = useState(0);

    return (
        <section className="log-in">
            <ToastContainer/>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        {step === 0 ? <SendEmail setStep={setStep} /> : step === 1? <CheckOtp setStep={setStep} /> : <ResetPassword />}
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="image-log">
                            <img className="text-right" src={logImg} alt={t('logo')} loading="lazy"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ForgotPasswordForm