import React from 'react'
import {useTranslation} from 'react-i18next';
import {Link} from "react-router-dom";
import 'react-confirm-alert/src/react-confirm-alert.css';
import "./selected.css"
import * as PropTypes from "prop-types";
import SingleAddressCard from "./SingleAddressCard";



function SelectableSingleAddress({address, select, selected}) {
    const {t} = useTranslation();

    const onSelectHandler = () => {
        select(address.id);
    }


    return (
        <section className="new-content-suite mt-4 d-flex align-items-center justify-content-between"
                 style={{gap: "2rem"}}>
            <button
                onClick={onSelectHandler}
                className={`shadow-el bg-white ${selected && 'selected'}`}
                style={{width: "42px", height: "42px", borderRadius: "10px", border: "10px solid #fff"}}>

            </button>
            <SingleAddressCard id={address.id} name={address.name} city={address.city} address={address.address} phone={address.phone} showActions={true}/>
        </section>
    )
}

export default SelectableSingleAddress