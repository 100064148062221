import React, {useEffect, useState} from "react";
import "./selectableShipment.css";
import {SRC_IMG_API} from "../apis/apis";
import boxImg from "../assets/svgs/package.svg";
import * as PropTypes from "prop-types";
import SingleShipmentCard from "./dashboard/SingleShipmentCard";

function PackagesList({item, checkHandler, selected}) {
    const [active, setActive] = useState(false);

    useEffect(() => {
        let filtered = selected.filter(selectedItem => selectedItem.id === item.id);

        if(filtered.length > 0){
            setActive(true)
        }else{
            setActive(false)
        }
    }, [selected]);

    return (
        <section className="new-content-suite mt-4 d-flex align-items-center justify-content-between"
                 style={{gap: "2rem"}}>
            <button
                onClick={() => checkHandler(item)}
                className={`shadow-el bg-white ${active && 'selected'}`}
                style={{width: "42px", height: "42px", borderRadius: "10px", border: "10px solid #fff"}}>

            </button>
            <SingleShipmentCard wasilUserTrackId={item.wasil_user_track_id} date={item.date}
                                totalWeight={item.total_weight} weightUnit={item.weight_unit} string={item.total_value}
                                packagesCount={item.packages_count} boxCount={item.box_count} branch={item.branch}/>
        </section>
    )
        ;
}

export default PackagesList;
