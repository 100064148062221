import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {GET_USER_ADDRESS} from "../../apis/apis";

const initialState = {
  addresses: []
};

export const getAddresses = createAsyncThunk("deliveryAddress", async () => {
  try {
    const { data } = await axios.get(GET_USER_ADDRESS, {
      headers: {
        apiKey: process.env.REACT_APP_API_KEY,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return data.data;
  } catch (error) {
    if (error.response.status === 401 ) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      window.location.pathname = "/signin";

    } else {
      console.log(error);
    }
  }
});

export const deliveryAddresses = createSlice({
  name: "deliveryAddresses",
  initialState,
  extraReducers: {
    [getAddresses.pending]: (state, action) => {
      state.loading = true;
    },
    [getAddresses.fulfilled]: (state, action) => {
      state.loading = false;
      state.addresses = action.payload;
    },
    [getAddresses.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default deliveryAddresses.reducer;
