import DashboardLayout from "../../../layouts/DashboardLayout";
import Breadcrumb from "../../../components/Breadcrumb";
import {NavLink, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {t} from "i18next";

import SingleAddress from "../../../components/dashboard/shipping-address/SingleAddress";
import {useDispatch, useSelector} from "react-redux";
import {getAddresses} from "../../../redux/features/deliveryAddressSlice";
import DashboardHeader from "../../../components/dashboard/DashboardHeader";

export const List = () => {
    const addresses = useSelector(state => state.deliveryAddresses.addresses)
    const navigator = useNavigate();
    const dispatch = useDispatch()
    const fetchData = async () => {
        dispatch(getAddresses());
    }
    useEffect(() => {
        fetchData();
    }, [dispatch]);

    const handelCreate = () => {
        navigator('/dashboard/delivery-address/create');
    }

    return (
        <DashboardLayout>
            <div className="home_content">
            <Breadcrumb>
                <li className="breadcrumb-item">
                    <NavLink to="/">{t("home")}</NavLink>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                    {t("Home Delivery Address")}
                </li>
            </Breadcrumb>
                <DashboardHeader />
                <div className="text-right mt-5">
                    <button onClick={handelCreate} className="btn btn-primary rounded-lg" style={{backgroundColor: '#7604FE'}}>{t('Create')}</button>
                </div>
                {addresses?.map((address) => {
                    return (

                        <SingleAddress key={address.id} address={address}/>
                    )
                })}
        </div>
        </DashboardLayout>
    )
}